import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/primary-button/button";
import styles from "./rebate-funds.module.scss";
export default function CreateRefCode({ setModal, submitting, submitFunction }) {
  const [isChecked, setIsChecked] = useState(false);

  const [code, setCode] = useState("");

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Ready to start earning?</h4>
        <p className="sm">
          Enter the referall code you would like to have and we will see if it is available.
          If it is, you will be able to start earning right away.
        </p>
      </div>
      <div className={styles["modal__body"]}>
        <div className={styles["input__outer"]}>
          <label htmlFor="">Enter a Text that is at least 5 characters long</label>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="***********"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                setCode((Math.random().toString(36)).substring(6));
              }}
            >Generate Random</button>
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          onClick={() => submitFunction(code)}
          text={"Create Referral Code"}
          type={"primary"}
          disabled={code.length < 5 || submitting}
        />
      </div>
      {
        code.length < 5 && (
          <div className={styles["error"]}>
            <br />
            <p>Referral code must be at least 5 characters long</p>
          </div>
        )
      }
    </>
  );
}
