import React, {useState, useEffect} from "react";
import styles from "./modals.module.scss";
import { copyIcon, chevronBottom } from "base/SVG";
import Button from "components/primary-button/button";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditApiKey({ setModal, selectedApiKey, updateAPIKey, deleteAPIKey, submitting }) {

  const [newAPIData, setNewAPIData] = useState({
    id: selectedApiKey.id,
    name: selectedApiKey.name,
    api_key: selectedApiKey.api_key,
    delete_ts: selectedApiKey.delete_ts,
    create_ts: selectedApiKey.create_ts,
    update_ts: selectedApiKey.update_ts,
  });

  useEffect(() => {
    setNewAPIData({
      id: selectedApiKey.id,
      name: selectedApiKey.name,
      api_key: selectedApiKey.api_key,
      delete_ts: selectedApiKey.delete_ts,
      create_ts: selectedApiKey.create_ts,
      update_ts: selectedApiKey.update_ts,
    });
  }, [selectedApiKey]);

  return (
    <>
      <div className={styles["modal__title"]}>
        <h4>Edit API Key </h4>
        <p className="sm">Manage user's API key</p>
      </div>
      <Button
          onClick={() => deleteAPIKey(newAPIData)}
          text={"Delete"}
          type={"primary"}
          style={{ width: "100%" }}
          disabled={submitting}
      />
      <br />
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">API full name</p>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="Sample Name"
              value={newAPIData.name}
              onChange={(e) =>
                setNewAPIData({ ...newAPIData, name: e.target.value })
              }
            />
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(newAPIData.name);
                toast.success("API name copied to clipboard", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }}
            >{copyIcon}</button>
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">API key</p>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="*******************"
              value={newAPIData.api_key}
              onChange={(e) =>
                setNewAPIData({ ...newAPIData, api_key: e.target.value })
              }
            />
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(newAPIData.api_key);
                toast.success("API key copied to clipboard", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }}
            >{copyIcon}</button>
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Status</p>
          <div className={styles["input"]}>
            <select
              value={newAPIData.delete_ts ? "Deleted" : newAPIData.disabled_ts ? "Disabled" : "Active"}
              onChange={(e) =>
                {
                  if (e.target.value === "Active") {
                    setNewAPIData({ ...newAPIData, disabled_ts: null });
                  } else if (e.target.value === "Disabled") {
                    setNewAPIData({ ...newAPIData, delete_ts: null, disabled_ts: new Date() });
                  }
                }
              }
            >
              <option value="Active">Active</option>
              <option value="Disabled">Disabled</option>
            </select>
            <button type="button">{chevronBottom}</button>
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          onClick={() => updateAPIKey(newAPIData)}
          text={"Update"}
          type={"primary"}
          disabled={submitting}
        />
      </div>
    </>
  );
}
