import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { copyIcon } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function EditReferralCode({ setModal, selectedRefCode, updateReferralCode, updatingReferralCode }) {

  const [newReferralCode, setNewReferralCode] = useState({
    id: selectedRefCode.id,
    code: selectedRefCode.code,
    status: selectedRefCode.status,
    delete_ts: selectedRefCode.delete_ts,
    create_ts: selectedRefCode.create_ts,
  });

  useEffect(() => {
    setNewReferralCode({
      id: selectedRefCode.id,
      code: selectedRefCode.code,
      status: selectedRefCode.status,
      delete_ts: selectedRefCode.delete_ts,
      create_ts: selectedRefCode.create_ts,
    });
  }, [selectedRefCode]);

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Edit Referral Code</h4>
        <p className="sm">
          Modify this referral code’s details. You can update the code or change
          its status.
        </p>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">Referral Code</p>
          <div className={styles["input"]}>
            <input type="text"
              value={newReferralCode.code}
              onChange={(e) => setNewReferralCode({ ...newReferralCode, code: e.target.value })}
            />
            <button
              type="button"
              className={styles["copy"]}
              onClick={() => {
                navigator.clipboard.writeText(newReferralCode.code);
                toast.success("Referral code copied to clipboard", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                }
              }
            >{copyIcon}</button>
          </div>
        </div>
        <label className={styles["check"]}>
          {/*<div className={styles["check__title"]}>
            <h6>Referral Status</h6>
            <p className="sm">
              Manage the status of this Referral code. Switching to Inactive
              disables the code immediately.
            </p>
          </div>
          {/*<div className={styles["check__box"]}>
            <input
              type="checkbox"
              checked={newReferralCode.delete_ts ? false : true}
              onChange={(e) => {
                console.log(e.target.checked);
                setNewReferralCode({
                  ...newReferralCode,
                  delete_ts: e.target.checked ? null : new Date().toISOString(),
                })
              }}
            />
            <span></span>
          </div>*/}
        </label>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          onClick={() => updateReferralCode(newReferralCode)}
          disabled={updatingReferralCode}
          text={"Update"}
          type={"primary"}
        />
      </div>
    </>
  );
}
