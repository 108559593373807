import React, { useState, useEffect } from "react";
import styles from "./settings.module.scss";
import { pencilIcon } from "base/SVG";
import Button from "components/primary-button/button";
import CheckToggle from "components/check-toogle/check-toggle";
import { AnimatePresence } from "framer-motion";
import ChangePassword from "./components/change-password";
import Modal from "App/components/modal/modal";
import ChangeFullname from "./components/change-fullname";
import DeleteAccount from "./components/delete-account";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";

export default function Settings() {
  const navigate = useNavigate();

  const [modal, setModal] = useState(null);
  
  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };

  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if user is logged in
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));

    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  const updateUser = (key, value) => {
    setUser((user) => ({ ...user, [key]: value }));
  };

  const updatePassword = (oldPassword, newPassword) => {
    // Check if user is logged in
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .post(
          SERVER_URL + "/api/auth/change-password",
          {
            old: oldPassword,
            password: newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token.split("=")[1]}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setModal(null);
        })
        .catch((error) => {
          toast.error("Error: " + error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const deleteUser = () => {
    // Check if user is logged in
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));

    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .delete(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          toast.success("Account deleted successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          // Remove token cookie
          document.cookie =
            "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          window.location.href = "../../auth/sign-in";
        })
        .catch((error) => {
          toast.error("An error occurred while deleting your account", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const update_tip_rebate_enabled = (tip_rebate_enabled) => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.put(SERVER_URL + '/api/auth/user', {
        tip_rebate_enabled: tip_rebate_enabled
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          updateUser("tip_rebate_enabled", tip_rebate_enabled);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });

      setModal(null);
    }
  }

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["settings"]}>
        <div className={styles["container"]}>
          <div className={styles["settings__inner"]}>
            <div className={styles["settings__head"]}>
              <h4>Account Settings</h4>
              <p className="sm">Manage your profile and account settings</p>
            </div>
            <div className={styles["settings__body"]}>
              <div className={styles["settings__body-item"]}>
                <h6>Full Name</h6>
                <p>
                  {user.first_name} {user.last_name}{" "}
                  <button
                    type="button"
                    onClick={() => setModal("change-fullname")}
                  >
                    {pencilIcon}
                  </button>
                </p>
              </div>{" "}
              <div className={styles["settings__body-item"]}>
                <h6>Email Address</h6>
                <p>{user.email}</p>
              </div>
              <div className={styles["settings__body-item"]}>
                <h6>Organization</h6>
                <p>{user.organization ? user.organization : "N/A"}</p>
              </div>
              <div className={styles["settings__body-item"]}>
                <h6>Country</h6>
                <p>{user.country ? user.country : "N/A"}</p>
              </div>
            </div>
            <div className={styles["settings__head"]}>
              <h4>Security Settings</h4>
              <p className="sm">
                Strengthen your account security with advanced options to keep
                your data safe.
              </p>
            </div>
            <div className={styles["settings__body"]}>
              <div className={styles["settings__body-act"]}>
                <div className={styles["settings__body-act-text"]}>
                  <h5>Update Password</h5>
                  <p className="sm">Change your old password to a new one</p>
                </div>
                <Button
                  text="Change Password"
                  type="gray"
                  onClick={() => setModal("change-password")}
                />
              </div>{" "}
              {/*<div className={styles["settings__body-act"]}>
                <div className={styles["settings__body-act-text"]}>
                  <h5>Two-Factor Authentication (2FA)</h5>
                  <p className="sm">
                    Enable 2FA to protect your account with an extra layer of
                    security.
                  </p>
                </div>
                <CheckToggle
                  checked={form.twofa}
                  onChange={(e) =>
                    updateForm({
                      twofa: e.target.checked,
                    })
                  }
                />
              </div>*/}
            </div>
            <div className={styles["settings__head"]}>
              <h4>Tip Rebate Program</h4>
            </div>
            <div className={styles["settings__body"]}>
              <div
                className={classNames(
                  styles["settings__body-act"],
                  styles["start"]
                )}
              >
                <div className={styles["settings__body-act-text"]}>
                  <h5>Enable Tip Rebate Program</h5>
                  <p className="sm">
                    By enabling NextBlock Exclusivity, you can receive a rebate
                    of 50% of all the revenue we generate from your tips on
                    MEV-protected transactions. This requires all transactions
                    to come to NextBlock exclusively, meaning you should not
                    send to Jito or other providers.
                  </p>
                </div>
                <div
                  className={styles["toogle"]}
                >
                  <CheckToggle
                    checkDivStyle={{ left: "100%" }}
                    checked={user.tip_rebate_enabled}
                    onChange={(e) =>
                      update_tip_rebate_enabled(e.target.checked)
                    }
                  />
                </div>
              </div>
              <Button
                onClick={() => navigate("/tip-rebate")}
                type={"gray"} text="Learn More" />
            </div>
            <div className={styles["settings__head"]}>
              <h4>Account Actions</h4>
            </div>
            <div className={styles["settings__body"]}>
              <div className={styles["settings__body-act"]}>
                <div className={styles["settings__body-act-text"]}>
                  <h5>Delete Account</h5>
                  <p className="sm">
                    Permanently delete your account and all associated data.
                    This action cannot be undone.
                  </p>
                </div>
                <Button
                  text="Delete Account"
                  type="gray"
                  onClick={() => setModal("delete-account")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === "change-password" && (
          <Modal closeFunc={closeFunc}>
            <ChangePassword
              setModal={setModal}
              updatePassword={updatePassword}
            />
          </Modal>
        )}{" "}
        {modal === "change-fullname" && (
          <Modal closeFunc={closeFunc}>
            <ChangeFullname
              setModal={setModal}
              data={user}
              updateData={updateUser}
            />
          </Modal>
        )}{" "}
        {modal === "delete-account" && (
          <Modal closeFunc={closeFunc}>
            <DeleteAccount setModal={setModal} deleteUser={deleteUser} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
