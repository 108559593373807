import React, { useMemo, useState } from "react";
import styles from "./table.module.scss";
import classNames from "classnames";
import { chevronRight } from "base/SVG";
//import { tableList } from "../../modul";
import { Pagination } from "components/pagination/pagination";
import { useNavigate } from "react-router-dom";

export default function Table({ users }) {
  const [activeList, setActiveList] = useState(users);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  return (
    <div className={styles["users"]}>
      <div className={styles["users__title"]}>
        <h4>Users List</h4>
        <button
          type="button"
          onClick={() => navigate("/admin/user-management")}
        >View All</button>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Email</th>
                <th>Status</th>
                <th>Subscription Plan</th>
                <th>Last Login</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((item, index) => {
                return (
                  <tr
                    onClick={() => navigate("/admin/user-management/user?id=" + item.id)}
                    key={index}
                  >
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.banned_from_login ? "banned" : item.throttled ? "throttled" : item.delete_ts ? "deleted" : "active"]
                        )}
                      >
                        <span></span>
                        {item.banned_from_login ? "Banned" : item.throttled ? "Throttled" : item.delete_ts ? "Deleted" : "Active"}
                      </div>
                    </td>
                    <td>
                      <div className={classNames(styles["table__plan"])}>
                        {item.subscription_plan}
                      </div>
                    </td>
                    <td>{item.last_login_ts}</td>
                    <td>
                      <div className={classNames(styles["table__action"])}>
                        <button type="button">{chevronRight}</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
