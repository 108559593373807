import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { calendarIcon, chevronBottom } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";
import Select from "components/select/select";
import DateInput from "components/date-input/date-input";

export default function EditBalance({ setModal, user, updateUserBalance, updatingUserBalance }) {

  const [newUserData, setNewUserData] = useState({
    balance: user.balance,
  });

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Adjust Balance</h4>
        <p className="sm">
          Adjust the users balance. Set the new balance the user should have. The difference will be calculated automaticly and the transaction will show as a "Admin Adjustment" in the users transaction history.
        </p>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">Balance</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={newUserData.balance}
              onChange={(e) =>
                setNewUserData({ ...newUserData, balance: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          text={"Update"}
          type={"primary"}
          disabled={updatingUserBalance}
          onClick={() => updateUserBalance(newUserData)}
        />
      </div>
    </>
  );
}
