import React from "react";
import styles from "../invite-earn.module.scss";
import Select from "components/select/select";
import {
  addMale,
  earnRewards,
  exchangeIcon,
  moneySend,
  rewardIcon,
  shareLink,
  usersIcon,
  copyIcon
} from "base/SVG";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const data = [
  {
    id: "1",
    icon: shareLink,
    title: "Share Your Link",
    text: "Share your unique referral link with friends and colleagues.",
  },
  {
    id: "2",
    icon: addMale,
    title: "Referrals Sign Up",
    text: "Your referrals sign up and start using NextBlock.",
  },
  {
    id: "3",
    icon: earnRewards,
    title: "Earn Rewards",
    text: "Earn 25% of your friends monthly plan payments as credit to your account",
  },
];
export default function Program({ setModal, referral_stats, getStats, selectedDuration, setSelectedDuration, durations }) {

  const updateSelectedDuration = (duration) => {
    setSelectedDuration(duration);

    // Calculate start and end date
    let startDate = new Date();
    let endDate = new Date();

    if (duration.id === "1") {
      startDate.setDate(startDate.getDate() - 30);
    } else if (duration.id === "2") {
      startDate.setDate(startDate.getDate() - 60);
    } else if (duration.id === "3") {
      startDate.setMonth(startDate.getMonth() - 6);
    }

    getStats(startDate, endDate);
  }

  return (
    <div className={styles["program"]}>
      <ToastContainer />
      <div className={styles["program__inner"]}>
        <div className={styles["program__head"]}>
          <h5>Your Referral Stats</h5>
          <div className={styles["select"]}>
            <Select
              list={durations}
              selected={selectedDuration}
              onChange={(item) => updateSelectedDuration(item)}
            />
          </div>
        </div>
        <div className={styles["stats"]}>
          <div className={styles["stats__item"]}>
            <div className={styles["stats__item-icon"]}>{usersIcon}</div>
            <div className={styles["stats__item-text"]}>
              <p className="sm">Total Referrals</p>
              <h4 className="big">{referral_stats.referred_users_count}</h4>
            </div>
          </div>
          <div className={styles["stats__item"]}>
            <div className={styles["stats__item-inner"]}>
              <div className={styles["stats__item-icon"]}>{rewardIcon}</div>
              <div className={styles["stats__item-text"]}>
                <p className="sm">Total Rewards Earned</p>
                <h4 className="big">
                  {referral_stats.total_earnings.toFixed(2)} <small>USDC</small>
                </h4>
              </div>
            </div>
            <div className={styles["stats__item-btns"]}>
              <Button
                type={"white"}
                text={"Convert Balance"}
                icon={exchangeIcon}
                onClick={() => setModal("convert")}
              />
              <Button
                onClick={() => setModal("withdraw")}
                type={"white"}
                text={"Request Payout"}
                icon={moneySend}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles["program__inner"]}>
        <div className={styles["program__head"]}>
          <h5>How It Works</h5>
        </div>
        <div className={styles["program__items"]}>
          {data.map((item, index) => {
            return <Item itemData={item} key={index} />;
          })}
        </div>
        <br />
        <h5>Send the link below to your friends and colleagues.</h5>
        <p>Do not forget to replace YOUR_CODE_HERE with one of your referral codes.</p>
        <div
          onClick={() => {
            navigator.clipboard.writeText("https://nextblock.io/auth?refCode=YOUR_CODE_HERE");
            toast.success("Link Example copied to clipboard", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }}
          className={styles["input"]}
        >
          <input type="text" value="https://nextblock.io/auth?refCode=YOUR_CODE_HERE" readOnly />
          <button
            type="button"
          >
            {copyIcon}
          </button>
        </div>
      </div>
    </div>
  );
}
const Item = ({ itemData }) => {
  return (
    <div className={styles["item"]}>
      <div className={styles["item__icon"]}>{itemData.icon}</div>
      <h5>{itemData.title}</h5>
      <p className="sm">{itemData.text}</p>
    </div>
  );
};
