import React, { useMemo, useState, useEffect } from "react";
import styles from "./tables.module.scss";
//import { tableList } from "../../modul";
import { dotsIcon } from "base/SVG";
import { Pagination } from "components/pagination/pagination";
import Dropdown from "components/dropdown/dropdown";
import classNames from "classnames";
import Button from "components/primary-button/button";

export default function Codes({ ref_codes, setModal, deleteRefCode }) {
  const [activeList, setActiveList] = useState(ref_codes);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }
    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, ref_codes]);
  console.log("activelist", activeList, "curretn", currentTableData);

  useEffect(() => {
    setActiveList(ref_codes);
  }, [ref_codes]);

  const [payment, setPayment] = useState(false);

  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__head"]}>
        <h4>Your Referral Codes</h4>
        <Button
          type={"primary"}
          text={"Create a New Referral Code"}
          onClick={() => setModal("create-ref-code")}
        />
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Referral Code</th>
                <th>Total Referrals</th>
                <th>Total Earnings</th>
                <th>Status</th>
                <th>Created On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    No referral codes yet
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.code}</td>
                    <td>{item.total_referred_users || 0}</td>
                    <td>{item.total_earnings || "0.00"}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.delete_ts == null ? "active" : "inactive"]
                        )}
                      >
                        {item.delete_ts == null && <>Active</>}
                        {item.delete_ts !== null && <>Inactive</>}
                      </div>
                    </td>
                    <td>{item.create_ts}</td>

                    <td>
                      <Dropdown btnInner={dotsIcon}>
                        <button
                          type="button"
                          onClick={() => {
                            deleteRefCode(item.id);
                          }}
                        >Delete</button>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
