import React, { useState, useEffect } from "react";
import styles from "./layout.module.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import UserManagement from "./pages/user-management/user-management";
import UserDetail from "./pages/user-detail/user-detail";
import PayoutRequests from "./pages/payout-requests/payout-requests";
import AuditLog from "./pages/audit-log/audit-log";
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../config";


export default function Admin() {
  const { pathname } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [payment, setPayment] = useState(false);
  const [hideSide, setHide] = useState(false);
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (payment || paymentMethod || menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [payment, paymentMethod, menu]);

  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  const getUserData = () => {
    // Check if user is logged in
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));

    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          logout();
        });
    }
  }


  useEffect(() => {
    getUserData();
  }, []);

  const logout = () => {
    // Delete token cookie
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Redirect to login page
    window.location.href = "../../auth/sign-in";
  };

  useEffect(() => {
    if (user.rank_id == 1) {
      window.location.href = "/app/dashboard";
    }
  }, [user]);

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }
  
  return (
    <>
      <Sidebar hideSide={hideSide} menu={menu} setMenu={setMenu} user={user} />
      <div className={styles["layout"]}>
        <div className={styles["container"]}>
          <div className={styles["layout__inner"]}>
            <Header menu={menu} setMenu={setMenu} hideSide={hideSide} user={user} logout={logout} />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/user-management/user" element={<UserDetail accessingUser={user} />} />
              <Route path="/payout-requests" element={<PayoutRequests />} />
              <Route path="/audit-log" element={<AuditLog />} />
              <Route
                path="*"
                element={<Navigate to="/admin/dashboard" replace={true} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
