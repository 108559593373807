import React, {useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { copyIcon } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";

export default function EditReferralCode({ setModal, createReferralCode, submitting }) {

  const [code, setCode] = useState("");

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Create Referral Code</h4>
        <p className="sm">
          Create a new referral code to share with your friends and family
        </p>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">Referral Code</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={code}
              placeholder="REF123ABC"
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          onClick={() => createReferralCode(code)}
          text={"Create"}
          type={"primary"}
          disabled={submitting}
        />
      </div>
    </>
  );
}
