import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { calendarIcon, chevronBottom } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";
import Select from "components/select/select";
import DateInput from "components/date-input/date-input";

export default function EditTipRebateTransaction({ setModal, selectedTransaction, transactionHistoryStatusTypes, transactionHistoryTypes, updateTransactionItem, updatingTransaction }) {

  const [newTransactionData, setNewTransactionData] = useState({
    id: selectedTransaction.id,
    value: selectedTransaction.value,
    usdc_value: selectedTransaction.usdc_value,
    tip_amount: selectedTransaction.tip_amount,
    transaction_id: selectedTransaction.transaction_id,
    status: selectedTransaction.status,
    type: selectedTransaction.type,
    date: new Date(selectedTransaction.date).toISOString().slice(0, 19).replace("T", " "),
  });

  useEffect(() => {

    let formatedDate = selectedTransaction.date;

    // Remove timezone offset
    formatedDate = new Date(formatedDate);
    //formatedDate = new Date(formatedDate.getTime() - formatedDate.getTimezoneOffset() * 60000);

    // Convert date to this format: 2024-11-30 13:57:42 in users GMT timezone
    formatedDate = formatedDate.toISOString().slice(0, 19).replace("T", " ");

    setNewTransactionData({
      id: selectedTransaction.id,
      value: selectedTransaction.value,
      usdc_value: selectedTransaction.usdc_value,
      tip_amount: selectedTransaction.tip_amount,
      transaction_id: selectedTransaction.transaction_id,
      status: selectedTransaction.status,
      type: selectedTransaction.type,
      date: formatedDate,
    });
  }, [selectedTransaction]);

  const updateTransactionDate = (date) => {
    if (date.startDate) {
      date = date.startDate;
    }

    // Remove timezone offset
    date = new Date(date);
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    // Convert date to this format: 2024-11-30 13:57:42 in users GMT timezone
    date = date.toISOString().slice(0, 19).replace("T", " ");

    setNewTransactionData((newTransactionData) => ({ ...newTransactionData, date: date }));
  };

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Edit Tip Rebate Transaction</h4>
        <p className="sm">
          Modify transaction details carefully. Some fields may be restricted to
          prevent inconsistencies.
        </p>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">Value</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={newTransactionData.value}
              onChange={(e) =>
                setNewTransactionData({ ...newTransactionData, value: e.target.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">USDC Value</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={newTransactionData.usdc_value}
              onChange={(e) =>
                setNewTransactionData({ ...newTransactionData, usdc_value: e.target.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Tip Amount</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={newTransactionData.tip_amount}
              onChange={(e) =>
                setNewTransactionData({ ...newTransactionData, tip_amount: e.target.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">TX</p>
          <div className={styles["input"]}>
            <input
              type="text"
              value={newTransactionData.transaction_id}
              onChange={(e) =>
                setNewTransactionData({ ...newTransactionData, transaction_id: e.target.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Transaction Status</p>
          <div className={styles["select"]}>
            <Select
              list={transactionHistoryStatusTypes.map((item) => ({ value: item.name, label: item.name }))}
              selected={transactionHistoryStatusTypes.map((item) => ({ value: item.name, label: item.name })).find((item) => item.value === newTransactionData.status)}
              onChange={(status) =>
                setNewTransactionData({ ...newTransactionData, status: status.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Transaction Type</p>
          <div className={styles["select"]}>
            <Select
              list={transactionHistoryTypes.map((item) => ({ value: item.name, label: item.name }))}
              selected={transactionHistoryTypes.map((item) => ({ value: item.name, label: item.name })).find((item) => item.value === newTransactionData.type)}
              onChange={(type) =>
                setNewTransactionData({ ...newTransactionData, type: type.value })
              }
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Transaction Date</p>
          <DateInput
            startDate={newTransactionData.date}
            endDate={newTransactionData.date}
            updateForm={updateTransactionDate}
            getNewData={console.log}
            allowTimeInput={true}
            dateRange={false}
          />
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          text={"Update"}
          type={"primary"}
          disabled={updatingTransaction}
          onClick={() => updateTransactionItem(newTransactionData)}
        />
      </div>
    </>
  );
}
