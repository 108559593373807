import React from "react";
import styles from "../tip-rebate.module.scss";
import Select from "components/select/select";
import {
  exchangeIcon,
  moneySend,
  rebateIcon,
  rewardIcon,
  usersIcon,
} from "base/SVG";
import Button from "components/primary-button/button";

export default function Program({ setModal, rebate_stats }) {
  return (
    <div className={styles["program"]}>
      <div className={styles["program__inner"]}>
        <div className={styles["program__head"]}>
          <h5>Overview</h5>
        </div>
        <div className={styles["stats"]}>
          <div className={styles["stats__inner"]}>
            <div className={styles["stats__icon"]}>{rebateIcon}</div>
            <div className={styles["stats__text"]}>
              <p className="sm">Total Rewards Earned</p>
              <h4 className="big">
                {rebate_stats.total_rebate.toFixed(2)} <small>SOL</small>
              </h4>
            </div>
          </div>
          <div className={styles["stats__btns"]}>
            <Button
              type={"white"}
              text={"Convert Balance"}
              icon={exchangeIcon}
              onClick={() => setModal("convert")}
            />
            <Button
              onClick={() => setModal("withdraw")}
              type={"white"}
              text={"Request Payout"}
              icon={moneySend}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
