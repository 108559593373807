import React, { useEffect, useMemo, useState } from "react";
import styles from "./table.module.scss";
import classNames from "classnames";
import { chevronRight, copyIcon, eyeIcon } from "base/SVG";
//import { apiKeyList } from "./modul";
import { useNavigate } from "react-router-dom";
import Button from "components/primary-button/button";
import { toast } from "react-toastify";

export default function UserApiKeys({ setModal, api_keys, setSelectedApiKey }) {
  const [activeList, setActiveList] = useState(api_keys ? api_keys : []);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  useEffect(() => {
    setActiveList(api_keys ? api_keys : []);
  }, [api_keys]);

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }
  
    date = new Date(date);
  
    return date.toLocaleDateString('en-US');
  };
  
  return (
    <div className={styles["users"]}>
      <div className={styles["users__title"]}>
        <h4>API Keys</h4>
        <div className={styles["btn"]}>
          <Button
            onClick={() => setModal("create-api-key")}
            text={"Add API Key"}
            type={"primary"}
          />
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>API Key</th>
                <th>Created</th>
                <th>Status</th>
                <th>Last updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No API keys found
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr onClick={() => {
                      if (item.delete_ts == null) {
                        setSelectedApiKey(item);
                        setModal("edit-api-key")
                      }
                    }} key={index}>
                    <td>{item.name}</td>
                    <td>
                      <div className={styles["table__key"]}>
                        <div className={styles["table__key-row"]}>
                          <div
                            className={styles["table__key-inner"]}
                            style={{ width: "150px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}
                            id={"api-key-div-"+item.id}
                          >
                            ******************************
                          </div>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();

                              const keyDiv = document.getElementById("api-key-div-"+item.id);

                              // Check if contains **********
                              if (keyDiv.innerHTML == "******************************") {
                                keyDiv.innerHTML = item.api_key;
                              } else {
                                keyDiv.innerHTML = "******************************";
                              }
                            }
                          }                              
                          >{eyeIcon}</button>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(item.api_key);
                              toast.success("API key copied to clipboard", {
                                  position: "top-right",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "dark",
                              });
                            }}
                          >{copyIcon}</button>
                        </div>
                      </div>
                    </td>
                    <td>{removeTimestamp(item.create_ts)}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.delete_ts ? "deleted" : item.disabled_ts ? "disabled" : "active"]
                        )}
                      >
                        <span></span>
                        {item.delete_ts ? "Deleted" : item.disabled_ts ? "Disabled" : "Active"}
                      </div>
                    </td>
                    <td>{removeTimestamp(item.update_ts)}</td>
                    <td>
                      <div className={classNames(styles["table__action"])}>
                        <button type="button">{chevronRight}</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
