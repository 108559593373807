import React, { useEffect, useState } from "react";
import styles from "./invite-earn.module.scss";
import Program from "./components/program";
import Codes from "./components/tables/codes";
import Modal from "App/components/modal/modal";
import Convert from "components/referral-funds/convert";
import ConvertSuccess from "components/referral-funds/convert-success";
import Withdraw from "components/referral-funds/withdraw";
import WithdrawSuccess from "components/referral-funds/withdraw-success";
import { AnimatePresence } from "framer-motion";
import Users from "./components/tables/users";
import CreateRefCode from "components/referral-funds/create-ref-code";
import CreateRefCodeSuccess from "components/referral-funds/create-ref-code-success";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function InviteEarn({ getUserData }) {
  const [loaded, setLoaded] = useState(false);

  const durations = [
    {
      id: "1",
      value: "Last 30 days",
    },
    {
      id: "2",
      value: "Last 60 days",
    },
    {
      id: "3",
      value: "Last 6 months",
    },
  ];
  const [selectedDuration, setSelectedDuration] = React.useState(durations[0]);

  const [modal, setModal] = useState(null);
  const [user, setUser] = useState({});
  const [referral_stats, setReferralStats] = useState({});
  const [referral_referred_users, setReferralReferredUsers] = useState(undefined);
  const [ref_codes, setRefCodes] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const updateUser = (key, value) => {
    setUser((user) => ({ ...user, [key]: value }));
  };

  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));
  
  useEffect(() => {
    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  const getStats = (startDate = undefined, endDate = undefined) => {
    setLoaded(false);

    // Convert date to DD/MM/YYYY format if not empty/null
    if (startDate) {
      setStartDate(startDate);
      startDate = new Date(startDate).toISOString(); //.split('T')[0]
    }
    if (endDate) {
      setEndDate(endDate);
      endDate = new Date(endDate).toISOString(); //.split('T')[0]
    }

    axios
    .get(SERVER_URL + "/api/referral/stats?startDate=" + startDate + "&endDate=" + endDate, {
      headers: {
        Authorization: `Bearer ${token.split("=")[1]}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setReferralStats(response.data);
    })
    .catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };

  useEffect(() => {

    if (Object.keys(user).length === 0) {
      return;
    }

    // Calculate start and end date
    let startDate = new Date();
    let endDate = new Date();

    if (selectedDuration.id === "1") {
      startDate.setDate(startDate.getDate() - 30);
    } else if (selectedDuration.id === "2") {
      startDate.setDate(startDate.getDate() - 60);
    } else if (selectedDuration.id === "3") {
      startDate.setMonth(startDate.getMonth() - 6);
    }

    console.log(startDate, endDate);

    getStats(startDate, endDate);

  }, [user]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const getReferredUsers = (startDate = undefined, endDate = undefined) => {
    setLoaded(false);

    // Convert date to DD/MM/YYYY format if not empty/null
    if (startDate) {
      setStartDate(startDate);
      startDate = new Date(startDate).toISOString().split('T')[0];
    }
    if (endDate) {
      setEndDate(endDate);
      endDate = new Date(endDate).toISOString().split('T')[0];
    }

    axios
      .get(SERVER_URL + "/api/referral/referred-users?startDate=" + startDate + "&endDate=" + endDate, {
        headers: {
          Authorization: `Bearer ${token.split("=")[1]}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReferralReferredUsers(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });

  };

  useEffect(() => {

    if (Object.keys(referral_stats).length === 0) {
      return;
    }

    getReferredUsers();
  }, [referral_stats]);

  useEffect(() => {

    if (referral_referred_users == undefined) {
      return;
    }

    axios.get(SERVER_URL + "/api/referral/codes", {
      headers: {
        Authorization: `Bearer ${token.split("=")[1]}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setRefCodes(response.data);
      setLoaded(true);
    })
    .catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });

  }, [referral_referred_users]);

  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const convert_balance = (amount) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/referral/convert', {
        amount: amount
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          getUserData();

          setModal("convert-success");

          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

      setModal(null);
    }
  }

  const withdraw_balance = (amount, payout_wallet) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/referral/payout', {
        amount: amount,
        payout_wallet: payout_wallet
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          getUserData();
          
          setModal("withdraw-success");

          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

      setModal(null);
    }
  }

  const createRefCode = (code) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/referral/code', {
        code: code,
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {

          // Add the new code to the list
          setRefCodes([...ref_codes, {
            id: response.data.refcode_id,
            code: code,
            total_referred_users: 0,
            total_earnings: 0,
            delete_ts: null,
            create_ts: new Date().toISOString()
          }]);

          setModal("create-ref-code-success");

          setSubmitting(false);

        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

    }
  }

  const deleteRefCode = (code_id) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.delete(SERVER_URL + '/api/referral/code/' + code_id, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          // Set the delete timestamp for the code
          setRefCodes(ref_codes.map((item) => {
            if (item.id == code_id) {
              item.delete_ts = new Date().toISOString();
            }

            return item;
          }));

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

    }
  }


  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["earn"]}>
        <div className={styles["container"]}>
          <div className={styles["earn__inner"]}>
            <div className={styles["earn__head"]}>
              <div className={styles["earn__head-text"]}>
                <h4>Referral Program</h4>
                <p className="sm">
                  Earn Rewards by Inviting Friends to NextBlock
                </p>
              </div>
            </div>
            <Program setModal={setModal} referral_stats={referral_stats} getStats={getStats} selectedDuration={selectedDuration} setSelectedDuration={setSelectedDuration} durations={durations} />
            <Codes ref_codes={ref_codes} setModal={setModal} deleteRefCode={deleteRefCode} />
            <Users referred_users={referral_referred_users} getNewData={getReferredUsers} startDate={startDate} endDate={endDate} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === "convert" && (
          <Modal closeFunc={closeFunc}>
            <Convert setModal={setModal} referral_stats={referral_stats} submitting={submitting} submitFunction={convert_balance} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "convert-success" && (
          <Modal closeFunc={closeFunc}>
            <ConvertSuccess setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "withdraw" && (
          <Modal closeFunc={closeFunc}>
            <Withdraw setModal={setModal} referral_stats={referral_stats} submitting={submitting} submitFunction={withdraw_balance} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "withdraw-success" && (
          <Modal closeFunc={closeFunc}>
            <WithdrawSuccess setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "create-ref-code" && (
          <Modal closeFunc={closeFunc}>
            <CreateRefCode setModal={setModal} submitting={submitting} submitFunction={createRefCode} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "create-ref-code-success" && (
          <Modal closeFunc={closeFunc}>
            <CreateRefCodeSuccess setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
