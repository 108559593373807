import React, { useEffect, useState } from "react";
import Search from "./components/search/search";
import Table from "./components/table/table";
import styles from "./user-management.module.scss";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function UserManagement() {
  const [loaded, setLoaded] = useState(false);
  
  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));

  const [user, setUser] = useState({});
  const [stats, setStats] = useState({});
  const [users, setUsers] = useState([]);

  const statusFilters = [
    {
      id: "1",
      value: "All Users",
    },
    {
      id: "2",
      value: "Active ",
    },
    {
      id: "3",
      value: "Banned",
    },
    {
      id: "4",
      value: "Throttled",
    },
    ,
    {
      id: "5",
      value: "Deleted",
    },
  ];
  const [selectedStatus, setSelectedStatus] = React.useState(statusFilters[0]);

  useEffect(() => {
    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const getUsers = () => {
    setLoadingUsers(true);

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/users?search=" + searchTerm + "&status=" + selectedStatus.value, {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUsers(response.data);
          setLoadingUsers(false);

          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      return;
    }

    getUsers();

  }, [user, selectedStatus]);

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <div className={styles["layout"]}>
      <Search getUsers={getUsers} setLoading={setLoadingUsers} loading={loadingUsers} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Table users={users} loading={loadingUsers} statusFilters={statusFilters} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
    </div>
  );
}
