import React, { useMemo, useState, useEffect } from "react";
import styles from "./table.module.scss";
import classNames from "classnames";
import { chevronRight, copyIcon, eyeIcon } from "base/SVG";
//import { referralList } from "./modul";
import { useNavigate } from "react-router-dom";
import Button from "components/primary-button/button";

export default function Referral({ setModal, referral_codes, setSelectedRefCode }) {
  const [activeList, setActiveList] = useState(referral_codes ? referral_codes : []);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  useEffect(() => {
    setActiveList(referral_codes ? referral_codes : []);
  }, [referral_codes]);

  return (
    <div className={styles["users"]}>
      <div className={styles["users__title"]}>
        <h4>Referral Code Management</h4>
        <div className={styles["btn"]}>
          <Button
            text={"Create a New Referral Code"}
            type={"primary"}
            onClick={() => setModal("create-referral-code")}
          />
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Referral Code</th>
                <th>Total Referrals</th>
                <th>Total Earnings</th>
                <th>Status</th>
                <th>Created On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No Referrals found
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr
                    onClick={() => {
                      setSelectedRefCode(item);
                      setModal("edit-referral-code")}
                    }
                    key={index}
                  >
                    <td>{item.code}</td>
                    <td>{item.total_referred_users || 0}</td>
                    <td>{item.total_earnings?.toFixed("2") || "0.00"}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.delete_ts ? "deleted" : "active"]
                        )}
                      >
                        <span></span>
                        {item.delete_ts	? "Deleted" : "Active"}
                      </div>
                    </td>
                    <td>{item.create_ts}</td>
                    <td>
                      <div className={classNames(styles["table__action"])}>
                        <button type="button">{chevronRight}</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
