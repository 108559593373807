import React from "react";
import styles from "./join.module.scss";
import Button from "components/primary-button/button";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Join() {
  const navigate = useNavigate();

  return (
    <section className={styles["join"]}>
      <div className="auto__container">
        <div className={styles["join__inner"]}>
          <div className={styles["join__inner-title"]}>
            <h2>Ready To Start Earning?</h2>
            <p>
              Activate your tip rebate program now to begin earning on your
              transactions
            </p>
          </div>
          <div className={styles["join__inner-foot"]}>
            <Button
              text="Activate"
              type={"primary"}
              onClick={() => navigate("/app/tip-rebate")}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
