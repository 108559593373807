import React, { useEffect, useState } from "react";
import styles from "./payout-requsts.module.scss";
import Table from "./components/table/table";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function PayoutRequests() {
  const [loaded, setLoaded] = useState(false);

  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));
  
  const [payoutRequests, setPayoutRequests] = useState([]);

  const [approvedFilter, setApprovedFilter] = useState({
    id: "1",
    value: "All Requests",
  });

  const [loadingPayoutRequests, setLoadingPayoutRequests] = useState(false);
  useEffect(() => {
    setLoadingPayoutRequests(true);

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {

      axios
      .get(SERVER_URL + "/api/admin/payout-requests?approved=" + (approvedFilter.id == "2" ? "approved" : approvedFilter.id == "3" ? "unapproved" : "all"),
        {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setPayoutRequests(response.data);
          setLoadingPayoutRequests(false);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [approvedFilter]);

  const markAsApproved = (id) => {
    let newPayoutRequests = payoutRequests.map((request) => {
      if (request.id == id) {
        request.done = true;
      }
      return request;
    });

    setPayoutRequests(newPayoutRequests);

    axios.put(SERVER_URL + "/api/admin/payout-request/" + id + "/approve",
      {},
      {
        headers: {
          Authorization: `Bearer ${token.split("=")[1]}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      
    })
    .catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      // If error set approved to false again
      let newPayoutRequests = payoutRequests.map((request) => {
        if (request.id == id) {
          request.done = false;
        }
        return request;
      });

      setPayoutRequests(newPayoutRequests);
    }
    );
  }

  const markAsUnapproved = (id) => {
    let newPayoutRequests = payoutRequests.map((request) => {
      if (request.id == id) {
        request.done = false;
      }
      return request;
    });

    setPayoutRequests(newPayoutRequests);

    axios.put(SERVER_URL + "/api/admin/payout-request/" + id + "/unapprove",
      {},
      {
        headers: {
          Authorization: `Bearer ${token.split("=")[1]}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      
    })
    .catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      // If error set approved to true again
      let newPayoutRequests = payoutRequests.map((request) => {
        if (request.id == id) {
          request.done = true;
        }
        return request;
      });

      setPayoutRequests(newPayoutRequests);
    }
    );
  }

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["payout"]}>
        <div className={styles["payout__title"]}>
          <h4>Payout Requests</h4>
          <p className="sm">
            Manage user payout requests for both the Tip Rebate Program and
            Referral Program.
          </p>
        </div>
        <Table data={payoutRequests} approve={markAsApproved} unapprove={markAsUnapproved} approvedFilter={approvedFilter} setApprovedFilter={setApprovedFilter} loading={loadingPayoutRequests} />
      </div>
    </>
  );
}
