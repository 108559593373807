import React, { useEffect, useState } from "react";
import styles from "./audit-log.module.scss";
import Table from "./components/table/table";
import Head from "./components/head";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function AuditLog() {
  const [loaded, setLoaded] = useState(false);
  
  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));

  const [auditLogs, setAuditLogs] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);

  const [userSearch, setUserSearch] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [actionTypeFilter, setactionTypeFilter] = useState({
    id: "-1",
    value: "All Actions",
  });

  useEffect(() => {
    setLoadingAuditLogs(true);

    // Wait for user to finish typing and call getTransactions
    const timeoutId = setTimeout(() => {
      getAuditLogs();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [userSearch]);
  
  const [loadingAuditLogs, setLoadingAuditLogs] = useState(false);
  
  const getAuditLogs = () => {
    setLoadingAuditLogs(true);

    let startDateISO = "";
    let endDateISO = "";
    if (startDate) {
      startDateISO = startDate.toISOString();
    }
    if (endDate) {
      endDateISO = endDate.toISOString();
    }

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      axios
      .get(SERVER_URL + "/api/admin/audit-logs?user=" + userSearch + "&start_date=" + startDateISO + "&end_date=" + endDateISO + "&action_type=" + actionTypeFilter.id,
        {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response", response.data);
          setAuditLogs(response.data);
          setLoadingAuditLogs(false);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const downloadAsCSV = () => {
    setDownloadingCSV(true);

    let startDateISO = "";
    let endDateISO = "";
    if (startDate) {
      startDateISO = startDate.toISOString();
    }
    if (endDate) {
      endDateISO = endDate.toISOString();
    }

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      axios
      .get(SERVER_URL + "/api/admin/audit-logs?downloadCSV=true&user=" + userSearch + "&start_date=" + startDateISO + "&end_date=" + endDateISO + "&action_type=" + actionTypeFilter.id,
        {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setDownloadingCSV(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");

          link
            .setAttribute("href", url);

          link.setAttribute("download", "audit-logs.csv");
          document.body.appendChild(link);

          link.click();

          link.remove();

        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }

        );
    }
  };

  useEffect(() => {

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      axios
      .get(SERVER_URL + "/api/admin/audit-log/types",
        {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setActionTypes(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }

  }, []);

  useEffect(() => {

    if (actionTypes.length == 0) {
      return;
    }

    getAuditLogs();
  }, [actionTypes, actionTypeFilter]);

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }
  
  return (
    <>
      <ToastContainer />
      <div className={styles["audit"]}>
        <div className={styles["audit__title"]}>
          <h4>Audit Logs</h4>
          <p className="sm">Maintain Transparency with Detailed Logs</p>
        </div>
        <Head userSearch={userSearch} setUserSearch={setUserSearch} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} actionTypeFilter={actionTypeFilter} setactionTypeFilter={setactionTypeFilter} actionTypes={actionTypes} getAuditLogs={getAuditLogs} downloadAsCSV={downloadAsCSV} downloadingCSV={downloadingCSV} />
        <Table data={auditLogs} loading={loadingAuditLogs} />
      </div>
    </>
  );
}
