import React, { useEffect, useState } from "react";
import styles from "./info.module.scss";
import { calendarIcon, updateIcon } from "base/SVG";
import classNames from "classnames";
import DateInput from "components/date-input/date-input";
import SelectSecondary from "components/select-secondary/select-secondary";

const statusList = [
  {
    id: "1",
    value: "Active",
  },
  {
    id: "2",
    value: "Banned",
  },
  {
    id: "3",
    value: "Deleted",
  },
];

const removeTimestamp = (date) => {

  if (!date) {
    return "";
  }

  date = new Date(date);

  return date.toLocaleDateString('en-US');
};

const addMonths = (date, months) => {

  if (!date) {
    return "";
  }

  date = new Date(date);

  date.setMonth(date.getMonth() + months);
  return date.toLocaleDateString('en-US');
}

export default function Info({ user, setUser, saveUser, subscriptionPlans, userRanks, setModal }) {
  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));

  const updateForm = (data) => {
    // Update last_subscription_payment in the user object
    setUser({
      ...user,
      last_subscription_payment: data
    });

  }

  const updateUserStatus = (status) => {

    console.log(status);

    if (status == "Active") {
      setUser({
        ...user,
        banned_from_login: false,
        delete_ts: "null"
      });

    } else if (status == "Banned") {
      setUser({
        ...user,
        banned_from_login: true,
        delete_ts: "null"
      });
    } else if (status == "Deleted") {
      setUser({
        ...user,
        banned_from_login: false,
        delete_ts: new Date()
      });
    }

  }

  return (
    <div className={styles["info"]}>
      <div className={styles["info__title"]}>
        <h6>{user.first_name} {user.last_name}</h6>
        <p className="sm">{user.email}</p>
      </div>
      <div className={styles["info__row"]}>
        <div className={styles["infoItem"]}>
          <p className="sm">User Balance Credit</p>
          <div className={styles["infoItem__row"]}>
            <h4 className="big">{user.balance.toFixed("2")}</h4>
            <button
              type="button"
              className={styles["button"]}
              onClick={() => setModal("edit-balance")}
            >
              {updateIcon}
              Update
            </button>
          </div>
        </div>
        <div className={styles["infoItem"]}>
          <p className="sm">Account Status</p>
          <div className={styles["infoItem__row"]}>
            <h6>{user.banned_from_login ? "Banned" : (user.delete_ts && user.delete_ts != "null") ? "Deleted" : "Active"}</h6>
            <div className={styles["select"]}>
              <SelectSecondary
                size={"fixed"}
                placeholder={"Change"}
                list={statusList}
                onChange={(status) => updateUserStatus(status.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles["infoItem"]}>
          <p className="sm">Current Subscription Plan</p>
          <div
            className={styles["infoItem__row"]}
          >
            <h6>{user.subscription_plan} | {user.runtime}</h6>
            <div className={styles["select"]}>
              <SelectSecondary
                size={"fixed"}
                placeholder={"Change"}
                list={subscriptionPlans.map((plan) => ({
                  id: plan.id,
                  value: plan.name + " | " + plan.runtime
                }))}
                onChange={(plan) => {
                  setUser({ ...user, subscription_plan_id: plan.id, subscription_plan: plan.value.split(" | ")[0], runtime: plan.value.split(" | ")[1] })
                  
                  if (!user.last_subscription_payment || user.last_subscription_payment == "0000-00-00 00:00:00") {
                    setUser({ ...user, last_subscription_payment: new Date(), subscription_plan_id: plan.id, subscription_plan: plan.value.split(" | ")[0], runtime: plan.value.split(" | ")[1] })
                  } else {
                    setUser({ ...user, subscription_plan_id: plan.id, subscription_plan: plan.value.split(" | ")[0], runtime: plan.value.split(" | ")[1] })
                  }
                }
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["info__foot"]}>
        <div className={styles["rank"]}>
          <div className={styles["rank__col"]}>
            <p className="sm">Rank</p>
            <h6>{user.rank_name}</h6>
          </div>
          <div className={styles["select"]}>
            <SelectSecondary
              size={"fixed"}
              placeholder={"Change"}
              list={userRanks.map((rank) => ({
                id: rank.id,
                value: rank.name
              }))}
              onChange={(rank) => setUser({ ...user, rank_id: rank.id, rank_name: rank.value })}
            />
          </div>
        </div>
        <div className={styles["date"]}>
          <div className={styles["date__row"]}>
            {
                (user.subscription_plan !== "Free" && user.runtime == "Monthly") && (
                  <>
                    <div className={styles["date__col"]}>
                      <p className="sm">Last Subscription Payment</p>
                      <DateInput
                        startDate={removeTimestamp(user.last_subscription_payment)}
                        endDate={removeTimestamp(user.last_subscription_payment)}
                        updateForm={updateForm}
                        getNewData={saveUser}
                        allowTimeInput={false}
                        dateRange={false}
                      />
                    </div>
                    <div className={styles["date__col"]}>
                      <p className="sm">Next Subscription Payment</p>
                      <DateInput
                        disabled={true}
                        startDate={removeTimestamp(addMonths(user.last_subscription_payment, 1))}
                        endDate={removeTimestamp(addMonths(user.last_subscription_payment, 1))}
                        updateForm={updateForm}
                        getNewData={console.log}
                        allowTimeInput={false}
                        dateRange={false}
                        showChevron={false}
                      />
                    </div>
                  </>
                )
            }
            {
                (user.subscription_plan !== "Free" && user.runtime == "Yearly") && (
                  <>
                    <div className={styles["date__col"]}>
                      <p className="sm">Last Subscription Payment</p>
                      <DateInput
                        startDate={removeTimestamp(user.last_subscription_payment)}
                        endDate={removeTimestamp(user.last_subscription_payment)}
                        updateForm={updateForm}
                        getNewData={saveUser}
                        allowTimeInput={false}
                        dateRange={false}
                      />
                    </div>
                    <div className={styles["date__col"]}>
                      <p className="sm">Next Subscription Payment</p>
                      <DateInput
                        disabled={true}
                        startDate={removeTimestamp(addMonths(user.last_subscription_payment, 12))}
                        endDate={removeTimestamp(addMonths(user.last_subscription_payment, 12))}
                        updateForm={updateForm}
                        getNewData={console.log}
                        allowTimeInput={false}
                        dateRange={false}
                        showChevron={false}
                      />
                    </div>
                  </>
                )
            }
            {
                (user.subscription_plan == "Free") && (
                  <h5>No Subscription Plan</h5>
                )
            }
          </div>

          {/*<button
            type="button"
            className={classNames(styles["button"], styles["last"])}
          >
            Edit Last Payment Date
            {calendarIcon}
          </button>*/}
        </div>
      </div>
    </div>
  );
}
