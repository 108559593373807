import React, { useEffect, useState } from "react";
import styles from "./tip-rebate.module.scss";
import Program from "./components/program";
import Codes from "./components/table/table";
import Modal from "App/components/modal/modal";
import Convert from "components/rebate-funds/convert";
import ConvertSuccess from "components/rebate-funds/convert-success";
import Withdraw from "components/rebate-funds/withdraw";
import WithdrawSuccess from "components/rebate-funds/withdraw-success";
import { AnimatePresence } from "framer-motion";
import Users from "./components/table/table";
import History from "./components/table/table";
import Tip from "./components/tip";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function TipRebate({ getUserData }) {
  const [loaded, setLoaded] = useState(false);

  const [modal, setModal] = useState(null);
  const [user, setUser] = useState({});
  const [rebate_history, setRebateHistory] = useState([]);
  const [rebate_stats, setRebateStats] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const updateUser = (key, value) => {
    setUser((user) => ({ ...user, [key]: value }));
  };

  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));
  
  useEffect(() => {
    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  useEffect(() => {

    if (Object.keys(user).length === 0) {
      return;
    }

    axios
      .get(SERVER_URL + "/api/rebate/stats", {
        headers: {
          Authorization: `Bearer ${token.split("=")[1]}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRebateStats(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  }, [user, modal]);

  useEffect(() => {

    if (Object.keys(rebate_stats).length === 0) {
      return;
    }

    axios
      .get(SERVER_URL + "/api/rebate/history", {
        headers: {
          Authorization: `Bearer ${token.split("=")[1]}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRebateHistory(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  }, [rebate_stats]);

  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const update_tip_rebate_enabled = (tip_rebate_enabled) => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.put(SERVER_URL + '/api/auth/user', {
        tip_rebate_enabled: tip_rebate_enabled
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          updateUser("tip_rebate_enabled", tip_rebate_enabled);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });

      setModal(null);
    }
  }

  const convert_balance = (amount) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/rebate/convert', {
        amount: amount
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          getUserData();

          setModal("convert-success");

          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

      setModal(null);
    }
  }

  const withdraw_balance = (amount, payout_wallet) => {
    setSubmitting(true);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/rebate/payout', {
        amount: amount,
        payout_wallet: payout_wallet
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          getUserData();
          
          setModal("withdraw-success");

          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setSubmitting(false);
        });

      setModal(null);
    }
  }

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["earn"]}>
        <div className={styles["container"]}>
          <div className={styles["earn__inner"]}>
            <div className={styles["earn__head"]}>
              <div className={styles["earn__head-text"]}>
                <h4>Tip Rebate Program</h4>
                <p className="sm">
                  Earn rewards for your tipping activity. See your rebates and
                  manage your rewards effortlessly.
                </p>
              </div>
            </div>
            <Program setModal={setModal} rebate_stats={rebate_stats} />
            <Tip user={user} updateUser={updateUser} update_tip_rebate_enabled={update_tip_rebate_enabled} />
            <History data={rebate_history} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === "convert" && (
          <Modal closeFunc={closeFunc}>
            <Convert user={user} setModal={setModal} rebate_stats={rebate_stats} submitting={submitting} submitFunction={convert_balance} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "convert-success" && (
          <Modal closeFunc={closeFunc}>
            <ConvertSuccess setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "withdraw" && (
          <Modal closeFunc={closeFunc}>
            <Withdraw user={user} setModal={setModal} rebate_stats={rebate_stats} submitting={submitting} submitFunction={withdraw_balance} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "withdraw-success" && (
          <Modal closeFunc={closeFunc}>
            <WithdrawSuccess setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
