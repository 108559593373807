import React, { useEffect, useMemo, useState } from "react";
import styles from "./table.module.scss";
import { Pagination } from "components/pagination/pagination";
//import { tableList } from "../../modul";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Table({ data, loading }) {
  const [activeList, setActiveList] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    console.log(activeList.length)
    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  useEffect(() => {
    setActiveList(data);
  }, [data, loading]);

  if (loading) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <div className={styles["users"]}>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>User ID</th>
                <th>Action Type</th>
                <th>Details</th>
                <th>IP Address</th>
              </tr>
            </thead>
            <tbody>
              {
                currentTableData.length == 0 && (
                  <tr>
                    <td colSpan="5" className="sm">
                      No data available
                    </td>
                  </tr>
                )
              }
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.create_ts}</td>
                    <td>{item.user_id}</td>
                    <td>{item.action}</td>
                    <td>{item.details}</td>
                    <td>{item.ip_address}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
