import React, { useEffect } from "react";
import logo from "assets/images/logo.png";
import styles from "./sidebar.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  analyticsIcon,
  apiIcon,
  billingIcon,
  dashboardIcon,
  settingsIcon,
  supportIcon,
  noteIcon,
  lookupIcon,
  usersIcon,
  payoutIcon,
  logoutIcon,
} from "base/SVG";
import classNames from "classnames";

export default function Sidebar({ menu, setMenu, user }) {
  const location = useLocation();
  const navigate = useNavigate();

  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  const [showAccount, setShowAccount] = React.useState(false);

  // Check if screensize smaller then 1180px
  const handleResize = () => {
    if (window.innerWidth < 1180) {
      setShowAccount(true);
    } else {
      setShowAccount(false);
    }
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={classNames(styles["sidebar"], {
        [styles.active]: menu,
      })}
      onClick={close}
    >
      <div className={styles["sidebar__inner"]}>
        <div className={styles["sidebar__head"]}>
          <Link
            to={"/admin/dashboard"}
            className={styles["sidebar__head-logo"]}
          >
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={styles["sidebar__body"]}>
          <NavLink
            to="/admin/dashboard"
            className={styles["sidebar__body-link"]}
          >
            {dashboardIcon} <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/admin/user-management"
            className={styles["sidebar__body-link"]}
          >
            {usersIcon} <span>User Management</span>
          </NavLink>
          {
            user.rank_permissions.find((permission) => permission.name === "manage_payout_requests") && (
              <NavLink
                to="/admin/payout-requests"
                className={styles["sidebar__body-link"]}
              >
                {payoutIcon} <span>Payout Requests</span>
              </NavLink>
            )
          }
          {
            user.rank_permissions.find((permission) => permission.name === "admin_view_audit_log") && (
              <NavLink
                to="/admin/audit-log"
                className={styles["sidebar__body-link"]}
              >
                {lookupIcon} <span>Audit Logs</span>
              </NavLink>
            )
          }
          <NavLink to="/app/dashaboard" className={styles["sidebar__body-link"]}>
            {logoutIcon} <span>Log Out</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
