import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import Overview from "./components/overview/overview";
import Table from "./components/table/table";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function Dashboard() {
  const [loaded, setLoaded] = useState(false);
  
  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));

  const [user, setUser] = useState({});
  const [stats, setStats] = useState({});
  const [users, setUsers] = useState([]);

  const durations = [
    {
      id: "1",
      value: "All time",
    },
    {
      id: "2",
      value: "1 month",
    },
    {
      id: "3",
      value: "3 months",
    },
    {
      id: "4",
      value: "6 months",
    },
  ];
  const [selectedDuration, setSelectedDuration] = React.useState(durations[0]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/auth/user", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  useEffect(() => {
    
    if (Object.keys(user).length === 0) {
      return;
    }

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/users", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [user]);

  const [loadingStats, setLoadingStats] = useState(false);
  const getStats = (startDate = undefined, endDate = undefined) => {
    setLoadingStats(true);

    // Convert date to DD/MM/YYYY format if not empty/null
    if (startDate) {
      setStartDate(startDate);
      startDate = new Date(startDate).toISOString().split('T')[0];
    }
    if (endDate) {
      setEndDate(endDate);
      endDate = new Date(endDate).toISOString().split('T')[0];
    }

    axios
    .get(SERVER_URL + "/api/admin/stats?startDate=" + startDate + "&endDate=" + endDate, {
      headers: {
        Authorization: `Bearer ${token.split("=")[1]}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setStats(response.data);
      setLoaded(true);
      setLoadingStats(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };

  useEffect(() => {
    console.log(selectedDuration);

    if (users.length === 0) {
      return;
    }

    // Calculate start and end date
    let startDate = new Date();
    let endDate = new Date();

    if (selectedDuration.id === "1") {
      startDate = undefined
      endDate = undefined
    } else if (selectedDuration.id == "2") {
      startDate.setDate(startDate.getDate() - 30);
    } else if (selectedDuration.id == "3") {
      startDate.setDate(startDate.getDate() - 60);
    } else if (selectedDuration.id == "4") {
      startDate.setMonth(startDate.getMonth() - 6);
    }

    getStats(startDate, endDate);

  }, [users, selectedDuration]);


  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <div className={styles["dashboard"]}>
      <ToastContainer />
      
      {
        loadingStats ? (
        <>
          <BarLoader
            color={"#26cead"}
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        </>
        ) : (
          <Overview durations={durations} selectedDuration={selectedDuration} setSelectedDuration={setSelectedDuration} stats={stats} getStats={getStats} />
        )
      }
      <Table users={users} />
    </div>
  );
}
