import React from "react";
import styles from "./overview.module.scss";
import { chevronBottom } from "base/SVG";
//import { overviewList } from "../../modul";
import { payoutIcon, timeIcon, userCheck, userIcon } from "base/SVG";
import Select from "components/select/select";

export default function Overview({ durations, selectedDuration, setSelectedDuration, stats, getStats }) {
  
  const overviewList = [
    {
      id: "1",
      icon: userIcon,
      title: "Total Users",
      number: stats.total_users,
    },
    {
      id: "2",
      icon: userCheck,
      title: "Active Subscriptions",
      number: stats.active_subscriptions,
    },
    {
      id: "3",
      icon: payoutIcon,
      title: "Pending Payouts",
      number: stats.pending_payouts.toFixed("2"),
      info: "USDC",
    },
    {
      id: "4",
      icon: timeIcon,
      title: "Active API Keys",
      number: stats.active_api_keys,
    },
  ];
  
  return (
    <div className={styles["overview"]}>
      <div className={styles["overview__title"]}>
        <h4>Dashboard Overview</h4>
        <p className="sm">Your Central Hub for Nextblock Insights</p>
      </div>
      <div className={styles["overview__content"]}>
        <div className={styles["overview__top"]}>
          <h5>Overview</h5>
          <div className={styles["select"]}>
            <Select
              list={durations}
              selected={selectedDuration}
              onChange={(item) => {
                setSelectedDuration(item);
              }}
            />
          </div>
        </div>
        <div className={styles["overview__row"]}>
          {overviewList.map((item, index) => {
            return <OverviewItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const OverviewItem = (props) => {
  return (
    <div className={styles["overviewItem"]}>
      <div className={styles["overviewItem__icon"]}>{props.icon}</div>
      <div className={styles["overviewItem__name"]}>
        <p className="sm">{props.title}</p>
        <h4 className="big">
          {props.number} {props.info ? <span>{props.info}</span> : ""}
        </h4>
      </div>
    </div>
  );
};
