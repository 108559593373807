import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { chevronBottom, dating } from "base/SVG";
import styles from "./date-input.module.scss";

export default function DateInput({
  startDate,
  endDate,
  disabled,
  placeholder,
  updateForm,
  errorClass,
  getNewData,
  allowTimeInput = true,
  dateRange = true,
  showChevron = true,
}) {

  const [isOpen, setIsOpen] = useState(false);
  const [startTime, setStartTime] = useState(
    startDate ? moment(startDate).format("HH:mm") : "00:00"
  );

  const [endTime, setEndTime] = useState(
    endDate ? moment(endDate).format("HH:mm") : "23:59"
  );

  const closeCalendar = () => {
    setIsOpen(false);

    getNewData(startDate, endDate);
  };

  useEffect(() => {
    if (startDate) {
      setStartTime(moment(startDate).format("HH:mm"));
    }
  }
  , [startDate]);

  useEffect(() => {
    if (endDate) {
      setEndTime(moment(endDate).format("HH:mm"));
    }
  }
  , [endDate]);

  const onDateChange = (dates) => {
    console.log(dates);

    if (dateRange) {
      const [start, end] = dates;
      updateForm({ startDate: start, endDate: end });
    } else {
      updateForm(dates);
    }
    
  };

  const handleStartTimeChange = (e) => {
    const newTime = e.target.value;

    setStartTime(newTime);
    
    if (startDate) {
      const updatedStartDate = moment(startDate).set({
        hour: parseInt(newTime.split(":")[0], 10),
        minute: newTime.split(":")[1],
      });

      updateForm({ startDate: updatedStartDate.toDate() });
    }
  };

  const handleEndTimeChange = (e) => {
    const newTime = e.target.value;
    
    setEndTime(newTime);
    
    if (endDate) {
      const updatedEndDate = moment(endDate).set({
        hour: parseInt(newTime.split(":")[0], 10),
        minute: newTime.split(":")[1],
      });
      updateForm({ endDate: updatedEndDate.toDate() });

      console.log(updatedEndDate.toDate());

    }
  };

  const customCalendarContainer = ({ className, children }) => (
    <div className={className}>
      {children}
      
      {allowTimeInput && (
          dateRange ? (
            <div className={styles["time-inputs"]}>
              <div className={styles["time-input"]}>
                <label>Start Time</label>
                <input
                  type="time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                  disabled={disabled}
                />
              </div>
              <div className={styles["time-input"]}>
                <label>End Time</label>
                <input
                  type="time"
                  value={endTime}
                  onChange={handleEndTimeChange}
                  disabled={disabled}
                />
              </div>
            </div>
          ) : (
            <div
              className={styles["time-inputs"]}
            >
              <div
                className={styles["time-input"]}
                style={{ width: "100%" }}
              >
                <label>Time</label>
                <input
                  type="time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                  disabled={disabled}
                />
              </div>
            </div>
          )
      )}
    </div>
  );

  return (
    <div
      className={`${styles["input"]} ${errorClass ? styles[errorClass] : ""}`}
    >
      <DatePicker
        disabled={disabled}
        onChange={onDateChange}
        value={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange={dateRange}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        className={styles["input__datepicker"]}
        calendarContainer={customCalendarContainer}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => closeCalendar()}
      />
      <div className={styles["input__icon"]}>{dating}</div>
      {
        showChevron && (
          <div className={styles["input__chev"]}>{chevronBottom}</div>
        )
      }
    </div>
  );
}
