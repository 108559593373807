import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { lockIcon } from "base/SVG";
import Button from "components/primary-button/button";
import Input from "components/input/input";
import Select from "components/select/select";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../../config';

export default function ChangeFullname({ setModal, data, updateData }) {

  const [first_name, setFirst_name] = useState(data.first_name);
  const [last_name, setLast_name] = useState(data.last_name);
  const [organization, setOrganization] = useState(data.organization);

  const saveChanges = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.put(SERVER_URL + '/api/auth/user', {
        first_name: first_name,
        last_name: last_name,
        organization: organization
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          updateData("first_name", first_name);
          updateData("last_name", last_name);
          updateData("organization", organization);
          updateData("country", selectedCountry.isoCode);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });

      setModal(null);
    }
  }

  const countries_iso_code = [
    { isoCode: "US", value: "United States" },
    { isoCode: "CA", value: "Canada" },
    { isoCode: "GB", value: "United Kingdom" },
    { isoCode: "AU", value: "Australia" },
    { isoCode: "DE", value: "Germany" },
    { isoCode: "FR", value: "France" },
    { isoCode: "IT", value: "Italy" },
    { isoCode: "JP", value: "Japan" },
    { isoCode: "KR", value: "South Korea" },
    { isoCode: "MX", value: "Mexico" },
    { isoCode: "NL", value: "Netherlands" },
    { isoCode: "RU", value: "Russia" },
    { isoCode: "ES", value: "Spain" },
    { isoCode: "SE", value: "Sweden" },
    { isoCode: "CH", value: "Switzerland" },
    { isoCode: "AE", value: "United Arab Emirates" },
    { isoCode: "AF", value: "Afghanistan" },
    { isoCode: "AL", value: "Albania" },
    { isoCode: "DZ", value: "Algeria" },
    { isoCode: "AS", value: "American Samoa" },
    { isoCode: "AD", value: "Andorra" },
    { isoCode: "AO", value: "Angola" },
    { isoCode: "AI", value: "Anguilla" },
    { isoCode: "AQ", value: "Antarctica" },
    { isoCode: "AG", value: "Antigua and Barbuda" },
    { isoCode: "AR", value: "Argentina" },
    { isoCode: "AM", value: "Armenia" },
    { isoCode: "AW", value: "Aruba" },
    { isoCode: "AT", value: "Austria" },
    { isoCode: "AZ", value: "Azerbaijan" },
    { isoCode: "BS", value: "Bahamas" },
    { isoCode: "BH", value: "Bahrain" },
    { isoCode: "BD", value: "Bangladesh" },
    { isoCode: "BB", value: "Barbados" },
    { isoCode: "BY", value: "Belarus" },
    { isoCode: "BE", value: "Belgium" },
    { isoCode: "BZ", value: "Belize" },
    { isoCode: "BJ", value: "Benin" },
    { isoCode: "BM", value: "Bermuda" },
    { isoCode: "BT", value: "Bhutan" },
    { isoCode: "BO", value: "Bolivia" },
    { isoCode: "BA", value: "Bosnia and Herzegovina" },
    { isoCode: "BW", value: "Botswana" },
    { isoCode: "BR", value: "Brazil" },
    { isoCode: "BN", value: "Brunei" },
    { isoCode: "BG", value: "Bulgaria" },
    { isoCode: "BF", value: "Burkina Faso" },
    { isoCode: "BI", value: "Burundi" },
    { isoCode: "KH", value: "Cambodia" },
    { isoCode: "CM", value: "Cameroon" },
    { isoCode: "CN", value: "China" },
    { isoCode: "CO", value: "Colombia" },
    { isoCode: "CR", value: "Costa Rica" },
    { isoCode: "HR", value: "Croatia" },
    { isoCode: "CU", value: "Cuba" },
    { isoCode: "CY", value: "Cyprus" },
    { isoCode: "CZ", value: "Czech Republic" },
    { isoCode: "DK", value: "Denmark" },
    { isoCode: "DO", value: "Dominican Republic" },
    { isoCode: "EC", value: "Ecuador" },
    { isoCode: "EG", value: "Egypt" },
    { isoCode: "EE", value: "Estonia" },
    { isoCode: "ET", value: "Ethiopia" },
    { isoCode: "FI", value: "Finland" },
    { isoCode: "GR", value: "Greece" },
    { isoCode: "HK", value: "Hong Kong" },
    { isoCode: "HU", value: "Hungary" },
    { isoCode: "IN", value: "India" },
    { isoCode: "ID", value: "Indonesia" },
    { isoCode: "IE", value: "Ireland" },
    { isoCode: "IL", value: "Israel" },
    { isoCode: "KE", value: "Kenya" },
    { isoCode: "KW", value: "Kuwait" },
    { isoCode: "MY", value: "Malaysia" },
    { isoCode: "NG", value: "Nigeria" },
    { isoCode: "NO", value: "Norway" },
    { isoCode: "NZ", value: "New Zealand" },
    { isoCode: "PH", value: "Philippines" },
    { isoCode: "PL", value: "Poland" },
    { isoCode: "PT", value: "Portugal" },
    { isoCode: "QA", value: "Qatar" },
    { isoCode: "RO", value: "Romania" },
    { isoCode: "SA", value: "Saudi Arabia" },
    { isoCode: "SG", value: "Singapore" },
    { isoCode: "TH", value: "Thailand" },
    { isoCode: "TR", value: "Turkey" },
    { isoCode: "UA", value: "Ukraine" },
    { isoCode: "VN", value: "Vietnam" },
    { isoCode: "ZA", value: "South Africa" },
    { isoCode: "ZW", value: "Zimbabwe" }
  ];
  
  
  const [selectedCountry, setSelectedCountry] = useState(countries_iso_code.find((country) => country.isoCode === data.country));

  return (
    <div className={styles["content"]}>
      <h4>Full Name</h4>
      <Input
        label="First Name"
        placeholder={"Enter your first name"}
        value={first_name}
        onChange={(e) => setFirst_name(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <Input
        label="Last Name"
        icon={lockIcon}
        placeholder={"Enter your last name"}
        value={last_name}
        onChange={(e) => setLast_name(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <Input
        label="Organization"
        placeholder={"Enter your organization"}
        value={organization}
        onChange={(e) => setOrganization(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <label
        style={{ color: "#ffffff", fontSize: "14px", fontWeight: "400", marginBottom: "12px", lineHeight: "17px" }}
        for="">Country</label>
      <Select
        list={countries_iso_code}
        selected={selectedCountry}
        searchable={true}
        onChange={(country) => setSelectedCountry(country)}
        placeholder="Search for your country"
        inputStyle={{ padding: "16px" }}
      />
      <div className={styles["space"]}></div>
      <div className={styles["space"]}></div>
      <div className={styles["space"]}></div>
      <div className={styles["content__btns"]}>
        <Button type="gray" text="Cancel" onClick={() => setModal(null)} />
        <Button
          type="primary"
          text="Save Changes"
          onClick={() => saveChanges()}
        />
      </div>
    </div>
  );
}
