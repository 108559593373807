import React, { useState } from "react";
import styles from "../audit-log.module.scss";
import Select from "components/select/select";
import { downloadIcon, filterIcon, userFilled } from "base/SVG";
import DateInput from "components/date-input/date-input";

export default function Head({ userSearch, setUserSearch, startDate, setStartDate, endDate, setEndDate, actionTypeFilter, setactionTypeFilter, actionTypes, getAuditLogs, downloadAsCSV, downloadingCSV }) {
  const updateForm = (data) => {

    setStartDate(data.startDate);
    setEndDate(data.endDate);

  };
  return (
    <div className={styles["head"]}>
      <h4>Log Table</h4>
      <div className={styles["selects"]}>
        <div className={styles["select"]}>
          <input
            type="text"
            placeholder="Performed By..."
            className={styles["input"]}
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
          />
        </div>
        <div className={styles["date"]}>
          <DateInput
            startDate={startDate}
            endDate={endDate}
            updateForm={updateForm}
            getNewData={getAuditLogs}
          />
        </div>
        <div className={styles["select"]}>
          <Select
            list={actionTypes.map((action) => ({
              id: action.id,
              value: action.name,
            }))}
            selected={actionTypeFilter}
            iconIn={filterIcon}
            onChange={(data) => {
              setactionTypeFilter(data);
            }}
          />
        </div>
        <button
          type="button"
          className={styles["btn"]}
          onClick={() => downloadAsCSV()}
          disabled={downloadingCSV}
        >
          {downloadIcon}Download Log
        </button>
      </div>
    </div>
  );
}
