import React from "react";
import styles from "./why.module.scss";
import { receiveIcon, telegramIcon,  withdrawIcon } from "base/SVG";
const whyList = [
  {
    id: "1",
    icon: telegramIcon,
    title: "Send Tips Through NextBlock",
    text: "Use NextBlock for all your tipping transactions.",
  },
  {
    id: "2",
    icon: receiveIcon,
    title: "Receive Rebates Automatically",
    text: "Once enrolled, rebates are credited to your account based on eligible transactions.",
  },
  {
    id: "3",
    icon: withdrawIcon,
    title: "Withdraw Anytime",
    text: "Once you’ve earned at least 1 USDC, you can request a payout to your personal wallet.",
  },
];
export default function Why() {
  return (
    <div className={styles["why"]}>
      <div className="auto__container sm">
        <div className={styles["why__head"]}>
          <br />
          <br />
          <h2>How the Program Works</h2>
          <p className="big">
          Earn rebates when you send tips exclusively through NextBlock.
          </p>
        </div>
        <div className={styles["why__inner"]}>
          {whyList.map((item, index) => {
            return <WhyItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const WhyItem = (props) => {
  return (
    <div className={styles["whyItem"]}>
      <div className={styles["whyItem__icon"]}>{props.icon}</div>
      <div className={styles["whyItem__content"]}>
        <h4 className="sm">{props.title}</h4>
        <p className="sm">{props.text}</p>
      </div>
    </div>
  );
};
