import React from "react";
import styles from "./api-settings.module.scss";

export default function ApiSettings({ user, setModal, setUser }) {
  return (
    <div className={styles["settings"]}>
      <div className={styles["settings__title"]}>
        <h6>API Settings</h6>
      </div>
      <div className={styles["settings__content"]}>
        {/*<label className={styles["check"]}>
          <div className={styles["check__title"]}>
            <h6>Ban API Key</h6>
            <p className="sm">
              Manage the status of user API keys. Once banned, keys will be
              denied access to all services.
            </p>
          </div>
          <div className={styles["check__box"]}>
            <input
              type="checkbox"

            />
            <span></span>
          </div>
        </label>*/}
        <label className={styles["check"]}>
          <div className={styles["check__title"]}>
            <h6>Throttle API Keys</h6>
            <p className="sm">
              Manage the request limits for user API keys. Once throttled, keys
              will have restricted access based on set limits.
            </p>
          </div>
          <div className={styles["check__box"]}>
            <input
              type="checkbox"
              checked={user.throttled}
              onChange={() => {
                setUser({ ...user, throttled: !user.throttled });
              }}
            />
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}
