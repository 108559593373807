import React from "react";
import styles from "./check-toggle.module.scss";
export default function CheckToggle({ placeholder, onChange, checked, checkDivStyle, black = false }) {
  return (
    <div className={styles["check__outer"]}>
      <div
        className={black ? styles["check-black"] : styles["check"]}
        style={checkDivStyle}
      >
        <input
          type="checkbox"
          placeholder={placeholder}
          onChange={onChange}
          checked={checked}
        />
        <label htmlFor=""></label>
      </div>
    </div>
  );
}
