import React, { useMemo, useState, useEffect } from "react";
import styles from "./table.module.scss";
import classNames from "classnames";
import { dotsIcon, searchIcon } from "base/SVG";
import { transactionList } from "./modul";
import { useNavigate } from "react-router-dom";
import { Pagination } from "components/pagination/pagination";
import Dropdown from "components/dropdown/dropdown";
import DateInput from "components/date-input/date-input";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function TipRebateTransactions({ user, setModal, transactions, loading, setLoading, setSelectedTransaction, getTransactions }) {
  const [activeList, setActiveList] = useState(transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({
    startDate: new Date(),
    endDate: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  useEffect(() => {
    setActiveList(transactions);
  }, [transactions]);

  const navigate = useNavigate();
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setLoading(true);
    
    let startDateISO = "";
    let endDateISO = "";
    if (form.startDate) {
      startDateISO = form.startDate.toISOString();
    }
    if (form.endDate) {
      endDateISO = form.endDate.toISOString();
    }

    // Wait for user to finish typing and call getTransactions
    const timeoutId = setTimeout(() => {
      getTransactions(searchValue, startDateISO, endDateISO);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchValue, form.startDate, form.endDate]);

  const getNewData = () => {
    setLoading(true);

    let startDateISO = "";
    let endDateISO = "";
    if (form.startDate) {
      startDateISO = form.startDate.toISOString();
    }
    if (form.endDate) {
      endDateISO = form.endDate.toISOString();
    }

    getTransactions(searchValue, startDateISO, endDateISO);
  };

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }
  
    date = new Date(date);
  
    return date.toLocaleDateString('en-US');
  };

  return (
    <div className={styles["users"]}>
      <div className={styles["users__title"]}>
        <div>
          <h4>User Tip Rebate Transactions</h4>
          <h5>Current Balance: {user?.rebate_balance.toFixed("4")} SOL</h5>
        </div>
        <div className={styles["users__row"]}>
          <div className={styles["search"]}>
            <input
              type="text"
              placeholder="Search by keywords"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchIcon}
          </div>
          <div className={styles["date"]}>
            <DateInput
              startDate={form?.startDate}
              endDate={form?.endDate}
              updateForm={updateForm}
              getNewData={getNewData}
            />
          </div>
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          {
            loading ? (
              <BarLoader
                color={"#26cead"}
                cssOverride={{
                  display: "block",
                  margin: "10vh auto",
                  borderColor: "red",
                }}
                size={150}
              />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Value</th>
                    <th>USDC Value</th>
                    <th>Tip Amount</th>
                    <th>TX</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Date</th>
                    {/*<th>Address</th>*/}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // If there are no transactions, display a message
                    activeList.length === 0 && (
                      <tr>
                        <td colSpan="7" className={styles["table__no-data"]}>
                          <br />
                          No transactions found
                        </td>
                      </tr>
                    )
                  }
                  {currentTableData.map((item, index) => {
                    let formatedDate = item.date;

                    // Remove timezone offset
                    formatedDate = new Date(formatedDate);
                    
                    formatedDate = removeTimestamp(formatedDate);
                    
                    //formatedDate = formatedDate.toISOString()
                    
                    return (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.value || "-"}</td>
                        <td>{item.usdc_value || "-"}</td>
                        <td>{item.tip_amount || "-"}</td>
                        <td
                          style={{
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(item.transaction_id);
                            toast.success("TX copied to clipboard", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            });
                          }}
                        >{item.transaction_id || "-"}</td>
                        <td>
                          <div
                            className={classNames(
                              styles["table__status"],
                              styles[item.status.toLowerCase()]
                            )}
                          >
                            <span></span>
                            {item.status}
                          </div>
                        </td>
                        <td>{item.type}</td>
                        <td>{formatedDate}</td>
                        {/*<td>{item.address}</td>*/}
                        <td>
                          <div className={styles["right"]}>
                            <Dropdown btnInner={dotsIcon}>
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedTransaction(item);
                                  setModal("edit-tip-rebate-transaction")
                                }}
                              >
                                Edit
                              </button>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
          }
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
