import React, { useEffect, useState } from "react";
import styles from "./user-detail.module.scss";
import Info from "./components/info/info";
import ApiSettings from "./components/api-settings/api-settings";
import { chevronLeftP } from "base/SVG";
import { Link } from "react-router-dom";
import UserApiKeys from "./components/table/user-api-keys";
import Transactions from "./components/table/transactions";
import TipRebateTransactions from "./components/table/tip-rebate-transactions";
import Referral from "./components/table/referral";
import Modal from "Admin/components/modal/modal";
import EditReferralCode from "./components/modals/edit-referral-code";
import CreateReferralCode from "./components/modals/create-referral-code";
import { AnimatePresence } from "framer-motion";
import EditTransaction from "./components/modals/edit-transaction";
import EditTipRebateTransaction from "./components/modals/edit-tip-rebate-transaction";
import EditBalance from "./components/modals/edit-balance";
import EditApiKey from "./components/modals/edit-api-key";
import CreateApiKey from "./components/modals/create-api-key copy";
import Success from "./components/modals/success";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// project import
import { SERVER_URL } from "../../../config";


export default function UserDetail({ accessingUser }) {
  const [loaded, setLoaded] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get('id');

  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedTipRebateTransaction, setSelectedTipRebateTransaction] = useState(null);
  const [selectedRefCode, setSelectedRefCode] = useState(null);

  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));

  const [unchangedUser, setUnchangedUser] = useState({});
  const [user, setUser] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [tipRebateTransactions, setTipRebateTransactions] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userRanks, setUserRanks] = useState([]);
  const [transactionHistoryStatusTypes, setTransactionHistoryStatusTypes] = useState([]);
  const [transactionHistoryTypes, setTransactionHistoryTypes] = useState([]);
  const [referral_codes, setReferralCodes] = useState(undefined);

  useEffect(() => {
    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/user/" + user_id, {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
          setUnchangedUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/subscription-plans", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubscriptionPlans(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [user]);

  useEffect(() => {
    if (subscriptionPlans.length === 0) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/user-ranks", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUserRanks(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (userRanks.length === 0) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/billing/transaction-history/status-types", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setTransactionHistoryStatusTypes(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [userRanks]);

  useEffect(() => {
    if (transactionHistoryStatusTypes.length === 0) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/billing/transaction-history/types", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setTransactionHistoryTypes(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [transactionHistoryStatusTypes]);

  useEffect(() => {
    if (transactionHistoryTypes.length === 0) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/user/" + user_id + "/referral-codes", {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setReferralCodes(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, [transactionHistoryTypes]);

  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const getTransactions = (searchQuery, startDate, endDate) => {
    setLoadingTransactions(true);

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/user/" + user_id + "/balance-history?search=" + searchQuery + "&start_date=" + startDate + "&end_date=" + endDate, {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setTransactions(response.data);
          setLoadingTransactions(false);

        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  const [loadingTipRebateTransactions, setLoadingTipRebateTransactions] = useState(false);
  const getTipRebateTransactions = (searchQuery, startDate, endDate) => {
    setLoadingTipRebateTransactions(true);

    // Check if user is logged in
    if (!token) {
      window.location.href = "../../auth/sign-in";
    } else {
      // User is logged in
      axios
        .get(SERVER_URL + "/api/admin/user/" + user_id + "/tip-rebate-history?search=" + searchQuery + "&start_date=" + startDate + "&end_date=" + endDate, {
          headers: {
            Authorization: `Bearer ${token.split("=")[1]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setTipRebateTransactions(response.data);
          setLoadingTipRebateTransactions(false);

          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  useEffect(() => {
    if (referral_codes == undefined) {
      return;
    }

    if (loaded) return; // Dont get this data if already loaded

    if (accessingUser.rank_permissions.find((permission) => permission.name === "manage_payout_requests")) {
      getTransactions();
    } else {
      setTransactions([]);
    }

    if (accessingUser.rank_permissions.find((permission) => permission.name === "tip_rebate_manage_balance_view")) {
      getTipRebateTransactions();
    } else {
      setTipRebateTransactions([]);
    }

    setLoaded(true);

  }, [referral_codes]);

  const [modal, setModal] = useState(null);
  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const saveUser = () => {

    try {
      user.last_subscription_payment = new Date(user.last_subscription_payment).toISOString();
    } catch (error) {
      
    }

    axios.put(SERVER_URL + '/api/admin/user/' + user_id, user, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setUnchangedUser(user);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setModal(null);
        setUser(unchangedUser);
      });
  }

  useEffect(() => {
    if (!loaded) return;

    // Check if the user data is different from the unchanged user data
    if (JSON.stringify(user) !== JSON.stringify(unchangedUser)) {
      saveUser();
    }

  }, [user]);

  const [savingAPIkey, setSavingAPIkey] = useState(false);
  const deleteAPIKey = (data) => {
    setSavingAPIkey(true);

    axios.delete(SERVER_URL + '/api/admin/user/' + user_id + '/api-key/' + data.id, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Set the delete_ts
        data.delete_ts = new Date().toISOString();

        // Update the api key in the user object
        let newAPIKeys = user.api_keys.map((key) => {
          if (key.id === data.id) {
            return data;
          }
          
          return key;
        });

        setUser({
          ...user,
          api_keys: newAPIKeys
        });

        setModal(null);

        setSavingAPIkey(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setSavingAPIkey(false);
      });
  }
  
  const updateAPIKey = (data) => {
    setSavingAPIkey(true);

    axios.put(SERVER_URL + '/api/admin/user/' + user_id + '/api-key/' + data.id, data, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Update the api key in the user object
        let newAPIKeys = user.api_keys.map((key) => {
          if (key.id === data.id) {
            return data;
          }

          return key;
        });

        setUser({
          ...user,
          api_keys: newAPIKeys
        });

        setModal(null);

        setSavingAPIkey(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setSavingAPIkey(false);
      });
  }

  const createAPIKey = (name, api_key) => {
    setSavingAPIkey(true);

    axios.post(SERVER_URL + '/api/admin/user/' + user_id + '/api-key', { name, api_key }, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Do the .map to force a re render
        let newAPIKeys = user.api_keys.map((key) => {
          return key;
        });
        newAPIKeys.push(response.data.api_key);

        setUser({
          ...user,
          api_keys: newAPIKeys
        });

        setModal("created-api-key");

        setSavingAPIkey(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setSavingAPIkey(false);
      });

  }

  const generateAPIKey = (name) => {
    setSavingAPIkey(true);

    axios.post(SERVER_URL + '/api/admin/user/' + user_id + '/api-key/generate', { name }, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Do the .map to force a re render
        let newAPIKeys = user.api_keys.map((key) => {
          return key;
        });
        newAPIKeys.push(response.data.api_key);

        setUser({
          ...user,
          api_keys: newAPIKeys
        });

        setModal("created-api-key");

        setSavingAPIkey(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setSavingAPIkey(false);

      });

  }

  const [updatingTransaction, setUpdatingTransaction] = useState(false);
  const updateTransactionItem = (data) => {
    setUpdatingTransaction(true);

    let tempData = {
      id: data.id,
      amount: data.amount,
      type_id: transactionHistoryTypes.find((item) => item.name == data.type).id,
      status_id: transactionHistoryStatusTypes.find((item) => item.name == data.status).id,
      create_ts: data.date,
    }

    axios.put(SERVER_URL + '/api/admin/user/' + user_id + '/balance-history/' + data.id, tempData, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Remove timezone offset
        let formatedDate = new Date(data.date);
        //formatedDate = new Date(formatedDate.getTime() - formatedDate.getTimezoneOffset() * 60000);

        // Convert date to this format: 2024-11-30 13:57:42 in users GMT timezone
        //formatedDate = formatedDate.toISOString().slice(0, 19).replace("T", " ");

        data.date = formatedDate;

        // Update the transaction in the user object
        let newTransactions = transactions.map((transaction) => {
          if (transaction.id === data.id) {
            return data;
          }

          return transaction;
        });

        setTransactions(newTransactions);

        setModal(null);

        setUpdatingTransaction(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setUpdatingTransaction(false);
      });

  }

  const [deletingTransaction, setDeletingTransaction] = useState(false);
  const deleteTransaction = (id) => {
    setDeletingTransaction(true);

    axios.delete(SERVER_URL + '/api/admin/user/' + user_id + '/balance-history/' + id, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      // Remove the transaction from the user object
      let newTransactions = transactions.filter((transaction) => transaction.id !== id);

      setTransactions(newTransactions);

      setDeletingTransaction(false);

    }).catch((error) => {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setDeletingTransaction(false);
    });

  }

  const [updatingTipRebateTransaction, setUpdatingTipRebateTransaction] = useState(false);
  const updateTipRebateTransaction = (data) => {
    setUpdatingTipRebateTransaction(true);

    let tempData = {
      id: data.id,
      value: data.value,
      usdc_value: data.usdc_value,
      tip_amount: data.tip_amount,
      transaction_id: data.transaction_id,
      type_id: transactionHistoryTypes.find((item) => item.name == data.type).id,
      status_id: transactionHistoryStatusTypes.find((item) => item.name == data.status).id,
      create_ts: data.date,
    }

    axios.put(SERVER_URL + '/api/admin/user/' + user_id + '/tip-rebate-history/' + data.id, tempData, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Remove timezone offset
        let formatedDate = new Date(data.date);
        //formatedDate = new Date(formatedDate.getTime() - formatedDate.getTimezoneOffset() * 60000);

        // Convert date to this format: 2024-11-30 13:57:42 in users GMT timezone
        //formatedDate = formatedDate.toISOString().slice(0, 19).replace("T", " ");

        data.date = formatedDate;

        // Update the transaction in the user object
        let newTransactions = tipRebateTransactions.map((transaction) => {
          if (transaction.id === data.id) {
            return data;
          }

          return transaction;
        });

        setTipRebateTransactions(newTransactions);

        setModal(null);

        setUpdatingTipRebateTransaction(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setUpdatingTipRebateTransaction(false);

      });

  }

  const [creatingReferralCode, setCreatingReferralCode] = useState(false);
  const createReferralCode = (code) => {
    setCreatingReferralCode(true);

    axios.post(SERVER_URL + '/api/admin/user/' +  user_id + '/referral-code', { code },
      {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Do the .map to force a re render
        let newReferralCodes = referral_codes.map((code) => {
          return code;
        });
        newReferralCodes.push({
          id: response.refcode.id,
          code: response.refcode.code,
          total_referred_users: 0,
          total_earnings: 0,
          create_ts: response.refcode.create_ts,
          delete_ts: null
        });

        setReferralCodes(newReferralCodes);	

        setModal("created-referral-code");

        setCreatingReferralCode(false);
      }
      )
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setCreatingReferralCode(false);
      }
      );

  }

  const [updatingReferralCode, setUpdatingReferralCode] = useState(false);
  const updateReferralCode = (data) => {
    setUpdatingReferralCode(true);

    axios.put(SERVER_URL + '/api/admin/user/' + user_id + '/referral-code/' + data.id, data, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Update the referral code in the user object
        let newReferralCodes = referral_codes.map((code) => {
          if (code.id === data.id) {
            return data;
          }

          return code;
        });

        setReferralCodes(newReferralCodes);

        setModal(null);

        setUpdatingReferralCode(false);
      }
      )
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setUpdatingReferralCode(false);
      }
      );

  }

  const [updatingUserBalance, setUpdatingUserBalance] = useState(false);
  const updateUserBalance = (data) => {
    setUpdatingUserBalance(true);

    axios.put(SERVER_URL + '/api/admin/user/' + user_id + '/balance', {newBalance: data.balance}, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Update the balance in the user object
        setUser({
          ...user,
          balance: Number(data.balance)
        });

        setModal(null);

        setUpdatingUserBalance(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setUpdatingUserBalance(false);
      });

  }

  if (!loaded) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["detail"]}>
        <Link to="/admin/user-management" className={styles["detail__back"]}>
          <div className={styles["detail__back-icon"]}>{chevronLeftP}</div>
          <span>Back</span>
        </Link>
        <div className={styles["detail__inner"]}>
          <Info user={user} setUser={setUser} saveUser={saveUser} subscriptionPlans={subscriptionPlans} userRanks={userRanks} setModal={setModal} />
          <ApiSettings user={user} setModal={setModal} setUser={setUser} />
          <UserApiKeys setModal={setModal} api_keys={user.api_keys} setSelectedApiKey={setSelectedApiKey} />
          <Referral setModal={setModal} referral_codes={referral_codes} setSelectedRefCode={setSelectedRefCode} />
          {
            accessingUser.rank_permissions.find((permission) => permission.name === "manage_payout_requests") && (
              <Transactions setModal={setModal} transactions={transactions} loading={loadingTransactions} setSelectedTransaction={setSelectedTransaction} deleteTransaction={deleteTransaction} deletingTransaction={deletingTransaction} getTransactions={getTransactions} setLoading={setLoadingTransactions} />
            )
          }
          {
            accessingUser.rank_permissions.find((permission) => permission.name === "tip_rebate_manage_balance_view") && (
              <TipRebateTransactions user={user} setModal={setModal} transactions={tipRebateTransactions} loading={loadingTipRebateTransactions} setSelectedTransaction={setSelectedTipRebateTransaction} getTransactions={getTipRebateTransactions} setLoading={setLoadingTipRebateTransactions} />
            )
          }
          {/* <Modals /> */}
        </div>
      </div>
      <AnimatePresence>
        {modal === "edit-balance" && (
          <Modal closeFunc={closeFunc}>
            <EditBalance setModal={setModal} user={user} updateUserBalance={updateUserBalance} updatingUserBalance={updatingUserBalance} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "edit-referral-code" && (
          <Modal closeFunc={closeFunc}>
            <EditReferralCode setModal={setModal} selectedRefCode={selectedRefCode} updateReferralCode={updateReferralCode} updatingReferralCode={updatingReferralCode} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "create-referral-code" && (
          <Modal closeFunc={closeFunc}>
            <CreateReferralCode setModal={setModal} createReferralCode={createReferralCode} submitting={creatingReferralCode} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "created-referral-code" && (
          <Modal closeFunc={closeFunc}>
            <Success setModal={setModal} topText={"Referral Code Created Successfully"} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "edit-transaction" && (
          <Modal closeFunc={closeFunc}>
            <EditTransaction setModal={setModal} selectedTransaction={selectedTransaction} transactionHistoryStatusTypes={transactionHistoryStatusTypes} transactionHistoryTypes={transactionHistoryTypes} updateTransactionItem={updateTransactionItem} updatingTransaction={updatingTransaction} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "edit-tip-rebate-transaction" && (
          <Modal closeFunc={closeFunc}>
            <EditTipRebateTransaction setModal={setModal} selectedTransaction={selectedTipRebateTransaction} transactionHistoryStatusTypes={transactionHistoryStatusTypes} transactionHistoryTypes={transactionHistoryTypes} updateTransactionItem={updateTipRebateTransaction} updatingTransaction={updatingTipRebateTransaction} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "edit-api-key" && (
          <Modal closeFunc={closeFunc}>
            <EditApiKey setModal={setModal} selectedApiKey={selectedApiKey} updateAPIKey={updateAPIKey} deleteAPIKey={deleteAPIKey} submitting={savingAPIkey} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "create-api-key" && (
          <Modal closeFunc={closeFunc}>
            <CreateApiKey setModal={setModal} generateAPIKey={generateAPIKey} createAPIKey={createAPIKey} submitting={savingAPIkey} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "created-api-key" && (
          <Modal closeFunc={closeFunc}>
            <Success setModal={setModal} topText={"API Key Created Successfully"} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
