import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Intro from "./components/intro/intro";
import Why from "./components/why/why";
import Faq from "./components/faq/faq";
import Join from "./components/join/join";
import Feature from "./components/feature/feature";

export default function TipRebate() {
  return (
    <>
      <main className="main sm">
        <Header />
        <div className="mainTop">
          <Intro />
          <Why />
        </div>
        <Feature />
        <Faq />
        <Join />
      </main>
      <Footer />
    </>
  );
}
