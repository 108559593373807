import React, { useState } from "react";
import styles from "./faq.module.scss";
import classNames from "classnames";
const faqList = [
  {
    id: "1",
    question: "Is there a minimum for payouts?",
    answer: "Yes, the minimum payout is 1 USDC.",
  },
  {
    id: "2",
    question: "Can I use these funds on my NextBlock account?",
    answer:
      "We prefer you withdraw to your personal wallet then you can use those to top up your NextBlock account if you wish.",
  },
  {
    id: "3",
    question:
      "What happens if I send tips to multiple providers while under the rebate program?",
    answer:
      "Your performance will be degraded and you will likely not land with NextBlock, as we only tip 90-99% of the userTip, so if you sent 100% of the tip on your Jito transactions, we will rarely outland the Jito Transaction.",
  },
];
export default function Faq() {
  const [faq, setFaq] = useState(null);

  return (
    <section className={styles["faq"]}>
      <div className="auto__container">
        <div className={styles["faq__inner"]}>
          <div className={styles["faq__inner-title"]}>
            <h2>Frequently Asked Questions</h2>
            <p>
              Got questions? Here are some quick answers to common concerns.
            </p>
          </div>
          <div className={styles["faq__inner-col"]}>
            {faqList.map((item, index) => {
              return (
                <FaqItem {...item} key={index} setFaq={setFaq} faq={faq} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
const FaqItem = (props) => {
  return (
    <div className={styles["faqItem"]}>
      <div
        className={classNames(
          styles["faqItem__head"],
          props.faq === props.id ? styles["active"] : ""
        )}
        onClick={() => {
          if (props.faq !== props.id) {
            props.setFaq(props.id);
          } else {
            props.setFaq(null);
          }
        }}
      >
        <h5>{props.question}</h5>
        <span></span>
      </div>

      <div
        className={classNames(
          styles["faqItem__body"],
          props.faq === props.id ? styles["active"] : ""
        )}
      >
        <p className="sm">{props.answer}</p>
      </div>
    </div>
  );
};
