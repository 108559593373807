import React from "react";
import styles from "./modals.module.scss";
import Button from "components/primary-button/button";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function CreateApiKey({ setModal, createAPIKey, generateAPIKey, submitting }) {

  const [api_key_name, setApiKeyName] = React.useState("");
  const [api_key, setApiKey] = React.useState("");

  const generateAPIKeyLocal = (api_key_name) => {
    if (api_key_name === "") {
      toast.error("Please enter a name for the API key",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      return;
    }

    generateAPIKey(api_key_name);
  }
      

  return (
    <>
      <ToastContainer />
      <div className={styles["modal__title"]}>
        <h4>Create a User API Key</h4>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">API Key name</p>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="Enter API key name"
              value={api_key_name}
              onChange={(e) => setApiKeyName(e.target.value)}
            />
          </div>
        </div>
        <div className={styles["input__outer"]}>
          <p className="sm">Enter API key</p>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="Enter API Key here"
              value={api_key}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
        </div>
        <Button
          text={"Auto Generate API Key"}
          type={"primary"}
          disabled={submitting}
          style={{ width: "100%", marginBottom: "1rem", marginTop: "0px" }}
          onClick={() => generateAPIKeyLocal(api_key_name)}
        />
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          text={"Create"}
          type={"primary"}
          disabled={submitting}
          onClick={() => createAPIKey(api_key_name, api_key)}
        />
      </div>
    </>
  );
}
