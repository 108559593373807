import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { calendarIcon, chevronBottom } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";
import Select from "components/select/select";
import DateInput from "components/date-input/date-input";

export default function CountryConfirm({ setModal, user, updateCountry, updatingCountry }) {

  const countries_iso_code = [
    { isoCode: "US", value: "United States" },
    { isoCode: "CA", value: "Canada" },
    { isoCode: "GB", value: "United Kingdom" },
    { isoCode: "AU", value: "Australia" },
    { isoCode: "DE", value: "Germany" },
    { isoCode: "FR", value: "France" },
    { isoCode: "IT", value: "Italy" },
    { isoCode: "JP", value: "Japan" },
    { isoCode: "KR", value: "South Korea" },
    { isoCode: "MX", value: "Mexico" },
    { isoCode: "NL", value: "Netherlands" },
    { isoCode: "RU", value: "Russia" },
    { isoCode: "ES", value: "Spain" },
    { isoCode: "SE", value: "Sweden" },
    { isoCode: "CH", value: "Switzerland" },
    { isoCode: "AE", value: "United Arab Emirates" },
    { isoCode: "AF", value: "Afghanistan" },
    { isoCode: "AL", value: "Albania" },
    { isoCode: "DZ", value: "Algeria" },
    { isoCode: "AS", value: "American Samoa" },
    { isoCode: "AD", value: "Andorra" },
    { isoCode: "AO", value: "Angola" },
    { isoCode: "AI", value: "Anguilla" },
    { isoCode: "AQ", value: "Antarctica" },
    { isoCode: "AG", value: "Antigua and Barbuda" },
    { isoCode: "AR", value: "Argentina" },
    { isoCode: "AM", value: "Armenia" },
    { isoCode: "AW", value: "Aruba" },
    { isoCode: "AT", value: "Austria" },
    { isoCode: "AZ", value: "Azerbaijan" },
    { isoCode: "BS", value: "Bahamas" },
    { isoCode: "BH", value: "Bahrain" },
    { isoCode: "BD", value: "Bangladesh" },
    { isoCode: "BB", value: "Barbados" },
    { isoCode: "BY", value: "Belarus" },
    { isoCode: "BE", value: "Belgium" },
    { isoCode: "BZ", value: "Belize" },
    { isoCode: "BJ", value: "Benin" },
    { isoCode: "BM", value: "Bermuda" },
    { isoCode: "BT", value: "Bhutan" },
    { isoCode: "BO", value: "Bolivia" },
    { isoCode: "BA", value: "Bosnia and Herzegovina" },
    { isoCode: "BW", value: "Botswana" },
    { isoCode: "BR", value: "Brazil" },
    { isoCode: "BN", value: "Brunei" },
    { isoCode: "BG", value: "Bulgaria" },
    { isoCode: "BF", value: "Burkina Faso" },
    { isoCode: "BI", value: "Burundi" },
    { isoCode: "KH", value: "Cambodia" },
    { isoCode: "CM", value: "Cameroon" },
    { isoCode: "CN", value: "China" },
    { isoCode: "CO", value: "Colombia" },
    { isoCode: "CR", value: "Costa Rica" },
    { isoCode: "HR", value: "Croatia" },
    { isoCode: "CU", value: "Cuba" },
    { isoCode: "CY", value: "Cyprus" },
    { isoCode: "CZ", value: "Czech Republic" },
    { isoCode: "DK", value: "Denmark" },
    { isoCode: "DO", value: "Dominican Republic" },
    { isoCode: "EC", value: "Ecuador" },
    { isoCode: "EG", value: "Egypt" },
    { isoCode: "EE", value: "Estonia" },
    { isoCode: "ET", value: "Ethiopia" },
    { isoCode: "FI", value: "Finland" },
    { isoCode: "GR", value: "Greece" },
    { isoCode: "HK", value: "Hong Kong" },
    { isoCode: "HU", value: "Hungary" },
    { isoCode: "IN", value: "India" },
    { isoCode: "ID", value: "Indonesia" },
    { isoCode: "IE", value: "Ireland" },
    { isoCode: "IL", value: "Israel" },
    { isoCode: "KE", value: "Kenya" },
    { isoCode: "KW", value: "Kuwait" },
    { isoCode: "MY", value: "Malaysia" },
    { isoCode: "NG", value: "Nigeria" },
    { isoCode: "NO", value: "Norway" },
    { isoCode: "NZ", value: "New Zealand" },
    { isoCode: "PH", value: "Philippines" },
    { isoCode: "PL", value: "Poland" },
    { isoCode: "PT", value: "Portugal" },
    { isoCode: "QA", value: "Qatar" },
    { isoCode: "RO", value: "Romania" },
    { isoCode: "SA", value: "Saudi Arabia" },
    { isoCode: "SG", value: "Singapore" },
    { isoCode: "TH", value: "Thailand" },
    { isoCode: "TR", value: "Turkey" },
    { isoCode: "UA", value: "Ukraine" },
    { isoCode: "VN", value: "Vietnam" },
    { isoCode: "ZA", value: "South Africa" },
    { isoCode: "ZW", value: "Zimbabwe" }
  ];


  const [selectedCountry, setSelectedCountry] = useState(countries_iso_code.find((country) => country.isoCode === user.geoip_country));

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Complete Profile</h4>
        <p className="sm">
          Please complete your profile to continue using the platform. Confirm your country to proceed.
        </p>
      </div>
      <div className={styles["modal__col"]}>
        <div className={styles["input__outer"]}>
          <p className="sm">Country</p>
          <div className={styles["select"]}>
            <Select
              list={countries_iso_code}
              selected={selectedCountry}
              searchable={true}
              onChange={(country) => setSelectedCountry(country)}
              placeholder="Search for your country"
            />
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button
          text={"Update"}
          type={"primary"}
          style={{ width: "100%" }}
          onClick={() => updateCountry(selectedCountry.isoCode)}
          disabled={updatingCountry}
        />
      </div>
    </>
  );
}
