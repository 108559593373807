import React, { useEffect, useState } from "react";
import logo from "assets/images/logo.png";
import styles from "./header.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Account from "components/account/account";
import { logoutIcon, settingsIcon, walletIcon } from "base/SVG";
export default function Header() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  return (
    <div className={styles["header"]}>
      <div className={"auto__container"}>
        <div className={styles["header__inner"]}>
          <Link to="/" className={styles["header__inner-logo"]}>
            <img src={logo} alt="logo" />
          </Link>
          {/*<div className={styles["header__inner-action"]}>
            <div id="balanceBtn" className={styles["balance"]}>
              <div className={styles["balance__icon"]}>{walletIcon}</div>
              <div className={styles["balance__title"]}>
                Balance: <span>630 USDC</span>
              </div>
            </div>
            <Account
              btnInner={
                <>
                  <div className={styles["avatar"]}>
                    <div className={styles["avatar__title"]}>M</div>
                  </div>
                </>
              }
            >
              <button type="button" onClick={() => navigate("/app/settings")}>
                {settingsIcon} <span>Settings</span>
              </button>
              <button type="button">
                {logoutIcon} <span>Logout</span>
              </button>
            </Account>
          </div>*/}
        </div>
      </div>
    </div>
  );
}
