import React from "react";
import classNames from "classnames";
import Button from "components/primary-button/button";
import styles from "./rebate-funds.module.scss";
import { successIcon } from "base/SVG";

export default function WithdrawSuccess({ setModal }) {
  return (
    <>
      <div className={styles["modal__icon"]}>{successIcon}</div>
      <div className={classNames(styles["modal__title"], styles["sm"])}>
        <h4>Withdrawal request submitted successfully!</h4>
        <p>Your funds will be processed shortly.</p>
      </div>
      <div className={classNames(styles["modal__foot"], styles["center"])}>
        <Button
          onClick={() => setModal(null)}
          text={"Go to Dashboard"}
          type={"primary"}
        />
      </div>
    </>
  );
}
