import React, { useMemo, useState } from "react";
import styles from "./tables.module.scss";
//import { userList } from "../../modul";
import { dotsIcon } from "base/SVG";
import { Pagination } from "components/pagination/pagination";
import DateInput from "components/date-input/date-input";

export default function Users({ referred_users, getNewData, startDate, endDate }) {
  const [activeList, setActiveList] = useState(referred_users);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }
    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  //console.log("activelist", activeList, "curretn", currentTableData);

  const [form, setForm] = useState({
    startDate: startDate,
    endDate: endDate
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__head"]}>
        <h4>Referred Users</h4>
        <div className={styles["date"]}>
          <DateInput
            startDate={form?.startDate}
            endDate={form?.endDate}
            updateForm={updateForm}
            getNewData={getNewData}
          />
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Referral Date</th>
                <th>Earned Value (USDC)</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    No referral users yet
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.user_id}</td>
                    <td>{item.create_ts}</td>
                    <td>{item.earnings || "0.00"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
