import React, { useMemo, useState } from "react";
//import { tableList } from "../../modul";
import { Pagination } from "components/pagination/pagination";
import classNames from "classnames";
import styles from "./table.module.scss";

export default function History({ data }) {
  const [activeList, setActiveList] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }
    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  console.log("activelist", activeList, "curretn", currentTableData);

  const [payment, setPayment] = useState(false);

  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__head"]}>
        <h4>Rebate History</h4>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Transaction ID</th>
                <th>Tip Amount</th>
                <th>Value</th>
                <th>USDC Value</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    No rebate history found
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.create_ts}</td>
                    <td>{item.type}</td>
                    <td>{item.transaction_id || "-"}</td>
                    <td>{item.tip_amount || "-"}</td>
                    <td>{item.value}</td>
                    <td>{item.usdc_value || "-"}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.status]
                        )}
                      >
                        {item.status === "Confirmed" && (
                          <>Approved</>
                        )}
                        {item.status === "Declined" && (
                          <>Declined</>
                        )}
                        {item.status === "Processing" && (
                          <>Processing</>
                        )}
                        {item.status === "Expired" && (
                          <>Expired</>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
