import CheckToggle from "components/check-toogle/check-toggle";
import React from "react";
import styles from "../tip-rebate.module.scss";
import bant from "assets/images/bant.png";
import Button from "components/primary-button/button";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Tip({ user, updateUser, update_tip_rebate_enabled }) {
  const navigate = useNavigate();
  
  return (
    <div className={styles["tip"]}>
      <div className={styles["tip__bant"]}>
        <img src={bant} alt="bant" />
      </div>
      <div className={styles["tip__inner"]}>
        <div className={styles["tip__inner-content"]}>
          <h4>Tip Rebate Program - NextBlock Exclusivity</h4>
          <p className="sm">
            By enabling NextBlock Exclusivity, you can receive a rebate of 50%
            of all the revenue we generate from your tips on MEV-protected
            transactions. This requires all transactions to come to NextBlock
            exclusively, meaning you should not send to Jito or other providers.
          </p>
          <Button
            type={"black"}
            text={"Learn More"}
            onClick={() => navigate("/tip-rebate")}
          />
        </div>
        <div className={styles["toggle"]}>
          <CheckToggle
            black={true}
            checked={user.tip_rebate_enabled}
            onChange={(e) =>
              update_tip_rebate_enabled(e.target.checked)
            }
          />
        </div>
      </div>
    </div>
  );
}
