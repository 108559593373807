import React from "react";
import styles from "./search.module.scss";
import Button from "components/primary-button/button";

export default function Search({ getUsers, loading, setLoading, searchTerm, setSearchTerm }) {

  React.useEffect(() => {
    setLoading(true);

    // Wait for user to stop typing
    const delayDebounceFn = setTimeout(() => {
      getUsers();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }
  , [searchTerm]);

  return (
    <div className={styles["search"]}>
      <div className={styles["search__title"]}>
        <h4>User Management</h4>
        <p className="sm">Manage Users and Their Platform Access</p>
      </div>
      <div className={styles["search__form"]}>
        <div className={styles["searchInput"]}>
          <input
            type="search"
            placeholder="Search by email, or user ID."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Button type={"primary"} text={"Search"} />
      </div>
    </div>
  );
}
