import React from "react";
import styles from "./modals.module.scss";
import { copyIcon, successIcon } from "base/SVG";
import classNames from "classnames";
import Button from "components/primary-button/button";

export default function Success({ setModal, topText }) {
  return (
    <>
      <div className={styles["modal__icon"]}>{successIcon}</div>
      <div className={classNames(styles["modal__title"], styles["sm"])}>
        <h4>{topText}</h4>
      </div>
      {/*<div className={styles["modal__col"]}>
        <div className={styles["input"]}>
          <input type="text" value="nb_live_1234abcd-5678efgh-9101ijkl" />
          <button type="button">{copyIcon}</button>
        </div>
      </div>*/}
      <div className={classNames(styles["modal__foot"], styles["center"])}>
        <Button
          onClick={() => setModal(null)}
          text={"Complete"}
          type={"primary"}
        />
      </div>
    </>
  );
}
