import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/primary-button/button";
import CheckToggle from "components/check-toogle/check-toggle";
import styles from "./rebate-funds.module.scss";
export default function Convert({ setModal, referral_stats, submitting, submitFunction }) {
  const [isChecked, setIsChecked] = useState(false);

  const [amount, setAmount] = useState(0);

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>You are about to convert your referall funds to your main balance</h4>
        <p className="sm">
          Once you convert funds from your referall balance to your main balance,
          it can’t be undone. Funds in your main balance cannot be withdrawn and
          can only be used to pay for subscriptions.
        </p>
      </div>
      <div className={styles["modal__body"]}>
        <div className={styles["input__outer"]}>
          <label htmlFor="">Enter Amount - Available: {referral_stats.referall_balance.toFixed(2)} USDC</label>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="0.00"
              id="amount-input"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                setAmount(Number(referral_stats.referall_balance.toFixed(2)));
              }}
            >All</button>
          </div>
        </div>
        <div
          className={classNames(styles["check"], {
            [styles.active]: isChecked,
          })}
          onClick={() => setIsChecked(!isChecked)}
        >
          <div className={styles["check__box"]}></div>
          <div className={styles["check__label"]}>
            I understand that this action cannot be undone.
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          text={"Convert"}
          type={"primary"}
          disabled={!isChecked || amount <= 0 || Number(amount) > Number(referral_stats.referall_balance.toFixed(2)) || isNaN(amount) || submitting}
          onClick={() => submitFunction(amount)}
        />
      </div>
    </>
  );
}
