import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/primary-button/button";
import styles from "./rebate-funds.module.scss";
export default function Withdraw({ setModal, referral_stats, submitting, submitFunction }) {
  const [isChecked, setIsChecked] = useState(false);

  const [amount, setAmount] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");

  console.log(Number(referral_stats.referall_balance.toFixed(2)));

  return (
    <>
      <div className={classNames(styles["modal__title"], styles["mid"])}>
        <h4>Ready to withdraw your referall funds?</h4>
        <p className="sm">
          Enter the amount you want to withdraw and your payout wallet address.
          Withdrawals are processed promptly, but please ensure your wallet
          address is correct to avoid issues.
        </p>
      </div>
      <div className={styles["modal__body"]}>
        <div className={styles["input__outer"]}>
          <label htmlFor="">Enter Amount - Available: {referral_stats.referall_balance.toFixed(2)} USDC</label>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="0.00"
              id="amount-input"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                setAmount(Number(referral_stats.referall_balance.toFixed(2)));
              }}
            >All</button>
          </div>
        </div>
        <div className={styles["space"]}></div>
        <div className={styles["input__outer"]}>
          <label htmlFor="">Payout Wallet Address</label>
          <div className={styles["input"]}>
            <input
              type="text"
              placeholder="Enter your SOL wallet address."
              id="wallet-address"
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </div>
        </div>
        <div
          className={classNames(styles["check"], {
            [styles.active]: isChecked,
          })}
          onClick={() => setIsChecked(!isChecked)}
        >
          <div className={styles["check__box"]}></div>
          <div className={styles["check__label"]}>
            I confirm that the wallet address entered is correct, and I
            understand that withdrawal requests cannot be reversed.
          </div>
        </div>
      </div>
      <div className={styles["modal__foot"]}>
        <Button onClick={() => setModal(null)} text={"Cancel"} type={"gray"} />
        <Button
          onClick={() => submitFunction(amount, walletAddress)}
          text={"Withdraw Funds"}
          type={"primary"}
          disabled={!isChecked || amount <= 0 || Number(amount) > Number(referral_stats.referall_balance.toFixed(2)) || isNaN(amount) || walletAddress === "" || walletAddress.length < 10 || submitting}
        />
      </div>
    </>
  );
}
