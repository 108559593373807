import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./table.module.scss";
import { chevronBottom, filterIcon, tickIcon } from "base/SVG";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { Pagination } from "components/pagination/pagination";
//import { tableList } from "./modul";
import Select from "components/select/select";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const filterList = [
  {
    id: "1",
    value: "All Requests",
  },
  {
    id: "2",
    value: "Approved",
  },
  {
    id: "3",
    value: "Not Approved",
  },
];
export default function Table({ data, approve, unapprove, approvedFilter, setApprovedFilter, loading }) {
  const [isActive, setIsActive] = useState(null);

  const [activeList, setActiveList] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  useEffect(() => {
    setActiveList(data);

    console.log(data);
  }, [data]);

  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__action"]}>
        <h4>Payout Requests</h4>
        <div className={styles["select"]}>
          <Select
            iconIn={filterIcon}
            list={filterList}
            selected={approvedFilter}
            onChange={(e) => setApprovedFilter(e)}
          />
        </div>
      </div>
      {
        !loading && (
          <div className={styles["table"]}>
            <div className={styles["table__inner"]}>
              <div className={styles["table__head"]}>
                <div className={styles["table__row"]}>
                  <div className={styles["table__th"]}>User ID</div>
                  <div className={styles["table__th"]}>Program Type</div>
                  <div className={styles["table__th"]}>Payout Amount (USDC)</div>
                  <div className={styles["table__th"]}>Payout Wallet</div>
                  <div className={styles["table__th"]}>Actions</div>
                  <div className={styles["table__btn"]}></div>
                </div>
              </div>
              <div className={styles["table__body"]}>
                {currentTableData.length === 0 && (
                  <div className={styles["table__item"]}>
                    <div className={styles["table__row"]}>
                      <div className={styles["table__td"]}>No Payout Requests Found</div>
                    </div>
                  </div>
                )}
                {currentTableData.map((item, index) => {
                  return (
                    <TableItem
                      isActive={isActive}
                      setIsActive={setIsActive}
                      itemData={item}
                      key={index}
                      approve={approve}
                      unapprove={unapprove}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles["table__pagination"]}>
              <p className="sm">
                Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
                {activeList.length} results
              </p>
              <Pagination
                currentPage={currentPage}
                totalCount={activeList.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )
      }
      {
        loading && (
          <BarLoader
            color={"#26cead"}
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )
      }
    </div>
  );
}
const TableItem = ({ itemData, isActive, setIsActive, approve, unapprove }) => {
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (itemData.done) {
      setApproved(true);
    } else {
      setApproved(false);
    }
  }, [itemData.done]);

  const btnRef = useRef(null);
  const clickHandler = (e) => {
    if (btnRef?.current && btnRef?.current?.contains(e.target)) {
      e.preventDefault();
    } else {
      if (isActive !== itemData.id && btnRef) {
        setIsActive(itemData.id);
      } else {
        setIsActive(null);
      }
    }
  };
  return (
    <div className={styles["table__item"]}>
      <div
        className={classNames(styles["table__row"], {
          [styles.active]: itemData.done,
        })}
        onClick={clickHandler}
      >
        <div style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className={styles["table__td"]}>{itemData.user_id}</div>
        <div style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className={styles["table__td"]}>{itemData.payout_program_sources_name}</div>
        <div style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className={styles["table__td"]}>{itemData.usdc_value}</div>
        <div style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className={styles["table__td"]}> {itemData.payout_wallet}</div>
        <div className={styles["table__td"]}>
          <div
            ref={btnRef}
            className={classNames(styles["table__approve"], {
              [styles.active]: approved,
            })}
            onClick={() => {
              if (itemData.done) {
                unapprove(itemData.id);
              } else {
                approve(itemData.id);
              }
            }}
          >
            {!approved ? <>Approve</> : <>{tickIcon} Approved</>}
          </div>
        </div>
        <div
          className={classNames(styles["table__btn"], {
            [styles.active]: isActive === itemData.id,
          })}
        >
          {chevronBottom}
        </div>
      </div>
      <AnimatePresence>
        {isActive === itemData.id && (
          <motion.div
            className={styles["block"]}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className={styles["block__head"]}>
              <p className="sm">More details</p>
            </div>
            <div className={styles["block__inner"]}>
              <div className={styles["block__inner-row"]}>
                <div className={styles["block__item"]}>
                  <p className="sm">User Email</p>
                  <p
                    style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >{itemData.email}</p>
                </div>
                <div className={styles["block__item"]}>
                  <p className="sm">API Key</p>
                  <p
                    style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >{itemData.api_key}</p>
                </div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(itemData.payout_wallet);
                    toast.success("Copied to clipboard", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  }}
                  className={styles["block__item"]}
                >
                  <p className="sm">Payout Wallet</p>
                  <p
                    style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {itemData.payout_wallet} 
                  </p>
                  <p>
                    <small>(click to copy)</small>
                  </p>
                </div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(itemData.usdc_value);
                    toast.success("Copied to clipboard", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  }}
                  className={styles["block__item"]}
                >
                  <p className="sm">Payout Amount</p>
                  <p
                    style={{ maxWidth: "200px", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {itemData.usdc_value} USDC
                  </p>
                  <p>
                    <small>(click to copy)</small>
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
