import React, { useState } from "react";
import image from "assets/images/tip-rebate/feature.png";
import styles from "./feature.module.scss";
import Button from "components/primary-button/button";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";

const featureList = [
  {
    id: "1",
    title: "Switch to NextBlock Exclusively",
    image: image,
    text: "Ensure all your tipping transactions are routed through NextBlock to remain eligible for rebates.",
  },
  {
    id: "2",
    title: "Provide a Wallet Address",
    image: image,
    text: "Enter your wallet address to receive your rebate payouts.",
  },
  {
    id: "3",
    title: "Sign Up for the Program",
    image: image,
    text: "Ready to get started? Click the button below to activate your rebate program and start earning.",
  },
];
export default function Feature() {
  const [isActive, setIsActive] = useState("1");
  const navigate = useNavigate();
  
  return (
    <div className={styles["feature"]}>
      <div className="auto__container sm">
        <div className={styles["feature__inner"]}>
          <div className={styles["feature__head"]}>
            <h2>How to Enable the Tip Rebate Program</h2>
            <p>
              Follow these steps to activate your rebates and start earning.
            </p>
          </div>
          <div className={styles["feature__list"]}>
            {featureList.map((item, index) => {
              return (
                <FeatureItem
                  isActive={isActive}
                  setIsActive={setIsActive}
                  {...item}
                  key={index}
                />
              );
            })}
          </div>

          <Button
            text="Activate"
            type={"primary"}
            onClick={() => navigate("/app/tip-rebate")}
          />
        </div>
      </div>
    </div>
  );
}
const FeatureItem = (props) => {
  return (
    <div className={styles["featureItem"]}>
      <div
        className={classNames(styles["featureItem__content"], {
          [styles.active]: props.isActive === props.id,
        })}
        onClick={() => props.setIsActive(props.id)}
      >
        <h4>{props.title}</h4>
        <p>{props.text}</p>
      </div>
      {props.isActive === props.id && (
        <AnimatePresence>
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className={styles["featureItem__image"]}
          >
            <img src={props.image} alt="feature" />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};
