import { chevronBottom } from "base/SVG";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styles from "./select.module.scss";

const Select = ({ selected = null, list, key = null, onChange, iconIn, searchable = false, inputStyle = {} }) => {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);
  const [searchTerm, setSearchTerm] = useState("");

  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);

  };

  useEffect(() => {
    setCurrentList(
      !currentSelected
        ? list
        : list.filter((item) => {
            let compareKey = key ?? "value";
            return item[compareKey] !== currentSelected[compareKey];
          })
    );
  }, [list, currentSelected]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    
    try {
      if (searchable && active) {
        document.getElementById("searchInput").focus();
      } 
    } catch (error) {
      
    }

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);

  }, [active]);

  useEffect(() => {
    if (searchable) {
      setCurrentList(
        list.filter((item) =>
          item.value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setCurrentList(list);
    }
  }, [searchTerm, list, searchable]);

  return (
    <div
      className={classNames(styles["select"], {
        [styles.active]: active,
      })}
      ref={wrapper}
    >
      {
        searchable ? (
            active ? (
              <div
                style={{ display: "none" }}
                className={styles["select__selected"]} onClick={toggleActive}>
                {iconIn ? (
                  <div className={styles["select__selected-icon"]}>{iconIn}</div>
                ) : (
                  <>
                    {currentSelected?.icon && <img src={currentSelected.icon} alt="" />}
                  </>
                )}
                <span>{currentSelected ? currentSelected.value : "Select"}</span>
                {chevronBottom}
              </div>
            ) : (
            <div style={inputStyle} className={styles["select__selected"]} onClick={toggleActive}>
              {iconIn ? (
                <div className={styles["select__selected-icon"]}>{iconIn}</div>
              ) : (
                <>
                  {currentSelected?.icon && <img src={currentSelected.icon} alt="" />}
                </>
              )}
              <span>{currentSelected ? currentSelected.value : "Select"}</span>
              {chevronBottom}
            </div>
            )
        ) : (
          <div className={styles["select__selected"]} onClick={toggleActive}>
            {iconIn ? (
              <div style={inputStyle} className={styles["select__selected-icon"]}>{iconIn}</div>
            ) : (
              <>
                {currentSelected?.icon && <img src={currentSelected.icon} alt="" />}
              </>
            )}
            <span>{currentSelected ? currentSelected.value : "Select"}</span>
            {chevronBottom}
          </div>
        )
      }


      {active && searchable && (
        <input
          type="text"
          id="searchInput"
          className={styles["select__search"]}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          style={inputStyle}
        />
      )}
      <div className={styles["select__options"]}>
        {currentList.map((item, index) => (
          <div
            className={styles["select__option"]}
            key={index}
            onClick={() => onClick(item)}
            style={inputStyle}
          >
            {item?.icon && <img src={item.icon} alt="" />}
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
