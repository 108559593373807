import React from "react";
import styles from "./intro.module.scss";
import Button from "components/primary-button/button";
import { giftIcon } from "base/SVG";
import shape from "assets/images/tip-rebate/shape.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Intro() {
  const navigate = useNavigate();
  
  return (
    <div className={styles["intro"]}>
      <div className={styles["intro__bg"]}>
        <div className={styles["intro__shape"]}>
          <img src={shape} alt="" />
        </div>
      </div>
      <div className="auto__container">
        <div className={styles["intro__inner"]}>
          <div className={styles["intro__tag"]}>
            {giftIcon}
            Begin Earning
          </div>
          <h1 className="sm">NextBlock Tip Rebate Program</h1>
          <p className="big">
            We reward users who choose to send their tips through our platform
            exclusively. By participating in the Tip Rebate Program, you can
            earn rebates on your tips, helping you maximize your transactions.
          </p>
          <Button
            text="Get Started"
            type={"primary"}
            onClick={() => navigate("/app/tip-rebate")}
          />

          <div className={styles["intro__inner-btn"]}></div>
        </div>
        <div className={styles["intro__inner"]}></div>
      </div>
    </div>
  );
}
