import React, { useEffect, useMemo, useState } from "react";
import styles from "./table.module.scss";
import classNames from "classnames";
import { chevronRight, filterIcon } from "base/SVG";
//import { tableList } from "../../modul";
import { Pagination } from "components/pagination/pagination";
import Select from "components/select/select";
import { useNavigate } from "react-router-dom";

// third party
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Table({ users, loading, selectedStatus, setSelectedStatus, statusFilters }) {
  const [activeList, setActiveList] = useState(users);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  useEffect(() => {
    setActiveList(users);
  }, [users]);

  if (loading) {
    return (
      <BarLoader
        color={"#26cead"}
        cssOverride={{
          display: "block",
          margin: "10vh auto",
          borderColor: "red",
        }}
        size={150}
      />
    );
  }

  return (
    <div className={styles["users"]}>
      <div className={styles["users__title"]}>
        <h4>Users List</h4>
        <div className={styles["select"]}>
          <Select
            list={statusFilters}
            selected={selectedStatus}
            iconIn={filterIcon}
            onChange={(item) => setSelectedStatus(item)}
          />
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Email</th>
                <th>Status</th>
                <th>Subscription Plan</th>
                <th>Last Login</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                currentTableData.length === 0 && (
                  <tr>
                    <td colSpan="6" className={styles["table__empty"]}>
                      <br />
                      No users found
                    </td>
                  </tr>
                )
              }
              {currentTableData.map((item, index) => {
                return (
                  <tr
                    onClick={() => navigate("/admin/user-management/user?id=" + item.id)}
                    key={index}
                  >
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>
                      <div
                        className={classNames(
                          styles["table__status"],
                          styles[item.banned_from_login ? "banned" : item.throttled ? "throttled" : item.delete_ts ? "deleted" : "active"]
                        )}
                      >
                        <span></span>
                        {item.banned_from_login ? "Banned" : item.throttled ? "Throttled" : item.delete_ts ? "Deleted" : "Active"}
                      </div>
                    </td>
                    <td>
                      <div className={classNames(styles["table__plan"])}>
                        {item.subscription_plan}
                      </div>
                    </td>
                    <td>{item.last_login_ts}</td>
                    <td>
                      <div className={classNames(styles["table__action"])}>
                        <button type="button">{chevronRight}</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
